/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useQuery,
} from '@tanstack/react-query';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Col, Row } from 'reactstrap';

import {
  getAuth, onAuthStateChanged, isSignInWithEmailLink, signInWithEmailLink,
} from 'firebase/auth';

import Header from '../../Header';
import DialogSetPassword from '../../Dialogs/DialogSetPassword';
import FeedBackAlert from '../../FeedBackAlert';
import Loader from '../../Loader';

import './style.css';

import '../../../utils/api/firebase-auth/onAuthStateChangeWatcher';

import getFirebaseInstance from '../../../firebase';
import DialogPasswordDeactivation from '../../Dialogs/DialogPasswordDeactivation';
import clearSession from '../../../utils/clearSession';
import { getUserProfile } from '../../../utils/api/firebase-functions';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Profile() {
  // session
  const [authMethod, setAuthMethod] = useState(undefined);

  const [value, setValue] = useState(1);
  const [auth, setAuth] = useState(false);
  const [firebaseSingleton, setFirebaseSingleton] = useState(null);
  const [firebaseSingletonState, setFirebaseSingletonState] = useState(null);
  const [isReadyToFetch, setIsReadyToFetch] = useState(false);

  // authMethodSwitch
  const [authMethodSwitchChecked, setAuthMethodSwitchChecked] = useState(authMethod === 'password');
  // Modal
  const [linkToPasswordDialogOpen, setlinkToPasswordDialogOpen] = useState(false);
  const [passwordToLinkDialogOpen, setPasswordToLinkDialogOpen] = useState(false);
  const [passwordUpdateDialogOpen, setPasswordUpdateDialogOpen] = useState(false);

  const kSession = JSON.parse(localStorage.getItem('KomeoSession'));
  if (kSession !== undefined && kSession !== null) {
    const hasSessionAdminProperty = Object.prototype.hasOwnProperty.call(kSession, 'admin');
    if (hasSessionAdminProperty) {
      alert('Authentification invalide, vous allez être redirigé.');
      clearSession();
    }
  }

  const handleOpen = () => {
    if (authMethod === 'link') {
      setlinkToPasswordDialogOpen(true);
    } else setPasswordToLinkDialogOpen(true);
  };

  const handleClose = () => {
    if (authMethod === 'link') {
      setlinkToPasswordDialogOpen(false);
    } else setPasswordToLinkDialogOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  async function defineReauthentificationNeed() {
    // Vérifier si la session utilisateur est trop ancienne
    const cauth = getAuth();
    const redirectResult = await cauth.getRedirectResult();
    return redirectResult;
  }

  const {
    isLoading, isError, data, error,
  } = useQuery({
    queryKey: ['getuserprofile'],
    queryFn: getUserProfile,
  });

  useEffect(() => {
    if (authMethod === undefined && data !== undefined) {
      setAuthMethod(data.authMethod);
    }
  }, [data]);

  useEffect(() => {
    // Récupérez l'instance de Firebase de manière asynchrone
    getFirebaseInstance().then((instance) => {
      setFirebaseSingleton(instance);
      setFirebaseSingletonState(true);
    });
  }, []);

  useEffect(() => {
    if (firebaseSingletonState && !isReadyToFetch) {
      // const { app } = firebaseSingleton;
      const localAuth = getAuth();
      setAuth(localAuth);
      setIsReadyToFetch(true);
    }
  }, [firebaseSingleton]);

  useEffect(() => {
    setAuthMethodSwitchChecked(authMethod === 'password');
  }, [authMethod]);

  const handleAuthMethodSwitchChange = (event) => {
    setAuthMethodSwitchChecked(event.target.checked);
  };

  const session = JSON.parse(localStorage.getItem('KomeoSession'));

  const authentMethodSwitchLabelPrefix = authMethodSwitchChecked ? 'activée' : 'désactivée';
  const authentMethodSwitchLabel = `Authentification par mot de passe ${authentMethodSwitchLabelPrefix}.`;

  useEffect(() => {
    if (auth !== false) {
      if (isSignInWithEmailLink(auth, window.location.href)) {
        // let email = localStorage.getItem('emailForSignIn');
        const email = localStorage.getItem('emailForSignIn');
        if (!email) {
          // email = window.prompt('veuillez renseigner votre adresse mail');
          alert('Vous devez ouvrir le lien sur le même appareil où vous avez renseigné votre adresse mail.');
          window.location.href = '/';
        }
        signInWithEmailLink(auth, email, window.location.href)
          .then((result) => {
            window.localStorage.removeItem('emailForSignIn');
            // get users' claims
            // todo: refacto with async function signIn() {
            result.user.getIdTokenResult()
              .then(
                (idTokenResult) => {
                  const customerClaims = idTokenResult.claims;
                  const komeoSession = {
                    token: parseInt(customerClaims.komeo_token, 10),
                    admin: customerClaims.admin,
                    // name: customerClaims.customerAccount,
                    customerAccount: customerClaims.customerAccount,
                    id: customerClaims.komeo_id,
                    mail: customerClaims.email,
                  };
                  window.localStorage.setItem('KomeoSession', JSON.stringify(komeoSession));
                  window.location.href = '/profile/me';
                },
              );
          }).catch((err) => {
            console.log(err);
          });
      }
    }
  }, [auth]);

  if (authMethod === undefined || isLoading) {
    return (
      <Loader />
    );
  }
  return (
    <>
      <Header
        admin
        auth={auth}
      />
      <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <Tabs value={value} onChange={handleChange} centered>
          <Tab label="Mes informations" {...a11yProps(0)} />
          <Tab label="Sécurité" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Row className="justify-content-center">
          <Col xs="7" sm="6" md="4" lg="3" xl="3" xxl="2">
            <FormControl fullWidth margin="dense" disabled variant="standard">
              <InputLabel htmlFor="user-info-name">
                Nom utilisateur
              </InputLabel>
              <Input id="user-info-name" defaultValue={kSession.customerAccount} />
            </FormControl>
          </Col>
          <Col xs="7" sm="6" md="4" lg="3" xl="3" xxl="2">
            <FormControl fullWidth margin="dense" disabled variant="standard">
              <InputLabel htmlFor="user-info-mail">Adresse mail</InputLabel>
              <Input id="user-info-mail" defaultValue={kSession.mail} />
            </FormControl>
          </Col>
        </Row>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Row className="justify-content-center">
          {/* {authMethod === 'link' && (
            <Col xs="12" sm="12" md="10" lg="8" xl="7" xxl="6">
              <FeedBackAlert type="success" sentence={[{ value: 'Le mot
               de passe a bien été modifié', bold: true }]} />
              <FeedBackAlert type="success" sentence={[{ value: 'L\'
              authentification à votre compte se fait désormais par lien mail', bold: true }]} />
              <FeedBackAlert type="success" sentence={[{ value: 'L\'
              authentification à votre compte se fait désormais par mot de passe', bold: true }]} />
              <FeedBackAlert type="error" sentence={[{ value: 'Impossible de
               modifier le mot de passe, veuillez réessayer plus tard', bold: true }]} />
              <FeedBackAlert type="error" sentence={[{ value: 'Le type
               d\'authentification n\'a pas pu être changé, veuillez réessayer
               plus tard', bold: true }]} />
            </Col>
          )} */}
          <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" className="d-flex justify-content-center">
            <DialogSetPassword open={linkToPasswordDialogOpen} onClose={handleClose} type="new" passwordUpdateDialogOpen={passwordUpdateDialogOpen} />
            <DialogPasswordDeactivation
              open={passwordToLinkDialogOpen}
              onClose={handleClose}
              auth={auth}
            />
            <FormControlLabel
              control={
                (
                  <Switch
                    checked={authMethodSwitchChecked}
                    onChange={handleOpen}
                    defaultChecked
                  />
)
}
              label={authentMethodSwitchLabel}
            />
          </Col>
        </Row>
        <Row id="change-password-section-submit" className="change-password-section justify-content-center">
          <Col xs="12" sm="12" md="12" lg="10" xl="6" xxl="2" className="d-flex justify-content-center">
            <DialogSetPassword
              open={passwordUpdateDialogOpen}
              onClose={() => { setPasswordUpdateDialogOpen(false); }}
              type="update"
            />
            <Button className="ml-2" variant="outlined" size="medium" disabled={!authMethodSwitchChecked} onClick={() => { setPasswordUpdateDialogOpen(true); }}>
              Modifier mon mot de passe
            </Button>
          </Col>
        </Row>
      </TabPanel>
    </>
  );
}
