/* eslint-disable default-param-last */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-irregular-whitespace */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  useQuery,
  useMutation,
} from '@tanstack/react-query';

import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
// import IconButton from '@mui/material/IconButton';
// import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
// import FilterListIcon from '@mui/icons-material/FilterList';
import LinkIcon from '@mui/icons-material/Link';
import PasswordIcon from '@mui/icons-material/Password';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import { visuallyHidden } from '@mui/utils';
import { CircularProgress } from '@mui/material';

import { getApp } from 'firebase/app';
import {
  getFunctions,
  httpsCallable,
  // connectFunctionsEmulator,
} from 'firebase/functions';
import { fetchAllFirebaseUsers } from '../../utils/api/firebase-functions';

import DialogUsersValidation from '../Dialogs/DialogUsersValidation';

// function createData(name, calories, fat, carbs, protein) {
function createUserData(uid, mail, komeoAccount, authMethod, lastSignInTime) {
  return {
    uid,
    mail,
    komeoAccount,
    authMethod,
    lastSignInTime,
  };
}
// let rows = [
//   createUserData('m@mathn.fr', 'Komeadmin', 'password', '9 janvier 2023'),
//   createUserData('antony@komeocreation.fr', 'Komeadmin', 'link', '10 mars 2023'),
//   createUserData('mathieu.neveu@viacesi.fr', 'KomeoClient', 'link', '14 février 2022'),
//   createUserData('hugo@komeocreation.fr', 'Komeadmin', 'password', '11 janvier 2023'),
//   createUserData('mathieu@komeocreation.fr', 'KomeoClient', 'link', '10 janvier 2023'),
//   createUserData('remi@infine.io', 'Yaago', 'link', '10 janvier 2023'),
//   createUserData('julien@leclubdesconnaisseurs.com', 'Le Club des Connaisseurs', 'link', '10 janvier 2023'),
//   createUserData('regis@komeocreation.fr', 'Komeadmin', 'link', '10 janvier 2023'),
//   createUserData('david@yaago.com', 'Yaago', 'password', '10 janvier 2023'),
//   createUserData('aplatevoet@formation-industries-ese.fr', undefined, 'link', '10 janvier 2023'),
//   createUserData('cleblond@formation-industries-ese.fr', undefined, 'link', '10 janvier 2023'),
//   createUserData('delphine.darbel@enovea.net', 'Enovea', 'link', '10 janvier 2023'),
//   createUserData('axel.dobbelaere@enovea.net', 'Enovea', 'link', '10 janvier 2023'),
//   createUserData('aferet@normandiewebschool.fr', 'NWS', 'link', '10 janvier 2023'),
//   createUserData('b.garry@normandiewebschool.fr', 'NWS', 'link', '10 janvier 2023'),
//   createUserData('s.dia@normandiewebschool.fr', 'NWS', 'password', '10 janvier 2023'),
//   createUserData('jlebaudy@courtage-locatif.com', 'Courtage Locatif', 'password', '10 janvier 2023'),
//   createUserData('michael@alumneye.fr', 'Alumneye', 'link', '10 janvier 2023'),
//   createUserData('charlotte@alumneye.fr', 'Alumneye', 'link', '10 janvier 2023'),
//   createUserData('basile@kraaft.fr', 'Kraaft', 'password', '10 janvier 2023'),
//   createUserData('maxime@kraaft.fr', 'Kraaft', 'link', '10 janvier 2023'),
// ];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const headCells = [
  {
    id: 'mail',
    numeric: false,
    disablePadding: true,
    label: 'Mail',
  },
  {
    id: 'komeoAccount',
    numeric: false,
    disablePadding: false,
    label: 'Compte Client',
  },
  {
    id: 'authMethod',
    numeric: false,
    disablePadding: false,
    label: 'Méthode de connexion',
  },
  {
    id: 'lastSignInTime',
    numeric: false,
    disablePadding: false,
    label: 'Date de dernière connexion',
  },
];

const LINK_METHOD_LABEL = 'Par lien unique';
const PASSWORD_METHOD_LABEL = 'Par mot de passe';

const DEFAULT_ORDER = 'asc';
const DEFAULT_ORDER_BY = 'mail';
const DEFAULT_ROWS_PER_PAGE = 5;

function EnhancedTableHead(props) {
  const {
    onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort,
  } = props;
  const createSortHandler = (newOrderBy) => (event) => {
    onRequestSort(event, newOrderBy);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            size="small"
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar({ selected, clientList }) {
  const numSelected = selected.length;
  const [customerAccountSelected, setCustomerAccountSelected] = useState('');

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 0, sm: 0 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(
            theme.palette.primary.main,
            theme.palette.action.activatedOpacity,
          ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          Modifier
          {' '}
          {numSelected === 1 ? (
            selected[0].mail
          ) : (
            <>
              {numSelected}
              {' '}
              utilisateurs
            </>
          )}
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          myKomeo
        </Typography>
      )}

      {numSelected > 0 && (
        <>
          {/* todo : component w addUserForm ? */}
          <FormControl variant="standard" fullWidth>
            <InputLabel id="update-komeo-account-select-label">Client Komeo</InputLabel>
            <Select
              fullWidth
              labelId="update-komeo-account-select-label"
              id="update-komeo-account-select"
              value={customerAccountSelected}
              onChange={(e) => {
                setCustomerAccountSelected(e.target.value);
              }}
            >
              {clientList.map((client) => (
                <MenuItem key={client.id} value={client.name}>{client.name}</MenuItem>
              ))}
              <MenuItem value="Komeadmin">Compte Admin</MenuItem>
            </Select>
          </FormControl>
          <DialogUsersValidation type="update" title="Modification" selected={selected} customerAccount={customerAccountSelected} />
          <Divider orientation="vertical" variant="middle" flexItem />
          <DialogUsersValidation
            type="resetAuth"
            title="Réinitialiser la méthode de connexion"
            selected={selected}
            customerAccount={customerAccountSelected}
          />
          {/* <DialogUsersValidation type="delete" title="Suppression" selected={selected} komeoAccount={customerAccountSelected} /> */}
        </>
      )}
      {/* : ( */}
      {/* <Tooltip title="Filter list"> */}
      {/* <IconButton> */}
      {/* <FilterListIcon /> */}
      {/* </IconButton> */}
      {/* </Tooltip> */}
      {/* )} */}
    </Toolbar>
  );
}
EnhancedTableToolbar.defaultProps = {
  selected: [{}],
};

EnhancedTableToolbar.propTypes = {
  selected: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string,
      mail: PropTypes.string,
    }),
  ),
  clientList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

export default function EnhancedTable({ clientList, users }) {
  // tanstack query (react-query)
  // const queryClient = useQueryClient();

  const [order, setOrder] = useState(DEFAULT_ORDER);
  const [orderBy, setOrderBy] = useState(DEFAULT_ORDER_BY);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [visibleRows, setVisibleRows] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [paddingHeight, setPaddingHeight] = useState(0);

  // const [users, setUsers] = useState(null);

  // let rows = [];

  const rows = users.map((user) => createUserData(
    user.uid,
    user.mail,
    user.customerAccount,
    user.authMethod,
    user.lastSignInTime,
  ));
  // Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
  // stableSort() brings sort stability to non-modern browsers (notably IE11). If you
  // only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
  // with exampleArray.slice().sort(exampleComparator)
  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const orderDefined = comparator(a[0], b[0]);
      if (orderDefined !== 0) {
        return orderDefined;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  useEffect(() => {
    let rowsOnMount = stableSort(
      rows,
      getComparator(DEFAULT_ORDER, DEFAULT_ORDER_BY),
    );

    rowsOnMount = rowsOnMount.slice(
      0 * DEFAULT_ROWS_PER_PAGE,
      0 * DEFAULT_ROWS_PER_PAGE + DEFAULT_ROWS_PER_PAGE,
    );

    setVisibleRows(rowsOnMount);
  }, []);

  const handleRequestSort = React.useCallback(
    (event, newOrderBy) => {
      const isAsc = orderBy === newOrderBy && order === 'asc';
      const toggledOrder = isAsc ? 'desc' : 'asc';
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);

      const sortedRows = stableSort(rows, getComparator(toggledOrder, newOrderBy));
      const updatedRows = sortedRows.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      );

      setVisibleRows(updatedRows);
    },
    [order, orderBy, page, rowsPerPage],
  );

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => ({ uid: n.uid, mail: n.mail }));
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, user) => {
    let selectedIndex = -1;
    const findIndex = selected.find((element, index) => {
      if (element.uid === user.uid) {
        selectedIndex = index;
        return true;
      } return false;
    });
    // selectedIndex = (findIndex === undefined) ? -1 : findIndex.index;
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, user);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = React.useCallback(
    (event, newPage) => {
      setPage(newPage);

      const sortedRows = stableSort(rows, getComparator(order, orderBy));
      const updatedRows = sortedRows.slice(
        newPage * rowsPerPage,
        newPage * rowsPerPage + rowsPerPage,
      );

      setVisibleRows(updatedRows);

      // Avoid a layout jump when reaching the last page with empty rows.
      const numEmptyRows = newPage > 0 ? Math.max(0, (1 + newPage) * rowsPerPage - rows.length) : 0;

      const newPaddingHeight = (dense ? 33 : 53) * numEmptyRows;
      setPaddingHeight(newPaddingHeight);
    },
    [order, orderBy, dense, rowsPerPage],
  );

  const handleChangeRowsPerPage = React.useCallback(
    (event) => {
      const updatedRowsPerPage = parseInt(event.target.value, 10);
      setRowsPerPage(updatedRowsPerPage);

      setPage(0);

      const sortedRows = stableSort(rows, getComparator(order, orderBy));
      const updatedRows = sortedRows.slice(
        0 * updatedRowsPerPage,
        0 * updatedRowsPerPage + updatedRowsPerPage,
      );

      setVisibleRows(updatedRows);

      // There is no layout jump to handle on the first page.
      setPaddingHeight(0);
    },
    [order, orderBy],
  );

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => {
    const isInObject = selected.find((element) => {
      if (element.mail === name) {
        return true;
      } return false;
    });
    return isInObject !== undefined;
    // return selected.indexOf(name) !== -1;
  };
  // rows = data.map((user) => createUserData(
  //   user.mail,
  //   user.komeoAccount,
  //   user.authMethod,
  //   user.lastSignInTime,
  // ));

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar selected={selected} clientList={clientList} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows
                ? visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.mail);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, { mail: row.mail, uid: row.uid })}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.mail}
                      selected={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.mail}
                      </TableCell>
                      <TableCell align="left">{row.komeoAccount}</TableCell>
                      <TableCell align="left">
                        {row.authMethod === 'link'
                          ? (
                            <>
                              <LinkIcon />
                              {' '}
                              <span>{LINK_METHOD_LABEL}</span>
                            </>
                          )
                          : (
                            <>
                              <PasswordIcon />
                              {' '}
                              <span>{PASSWORD_METHOD_LABEL}</span>
                            </>
                          )}
                      </TableCell>
                      <TableCell align="left">
                        {row.lastSignInTime}
                      </TableCell>
                    </TableRow>
                  );
                })
                : null}
              {paddingHeight > 0 && (
              <TableRow
                style={{
                  height: paddingHeight,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Affichage dense"
      />
    </Box>
  );
}

EnhancedTable.propTypes = {
  clientList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      mail: PropTypes.string.isRequired,
      customerAccount: PropTypes.string.isRequired,
      authMethod: PropTypes.string.isRequired,
      lastSignInTime: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};
