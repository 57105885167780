/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './style.css';

import PasswordChecklist from 'react-password-checklist';

import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Visibility from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';

import { Row, Col } from 'reactstrap';

import FeedBackAlert from '../../FeedBackAlert';

import { verifyCorrectPassword, compareTwoInputs } from '../../../utils/formValidation';

import { updateFirebaseUserPassword } from '../../../utils/api/firebase-functions';
import { checkUserToken, getUserEmail } from '../../../utils/api/firebase-auth';
import Reauthenticate from '../DialogContent/Reauthenticate';

export default function DialogSetPassword({
  open, onClose, type, passwordUpdateDialogOpen,
}) {
  const [dialogTitle, setDialogTitle] = useState(type === 'new' ? 'Nouveau mot de passe' : 'Modifier mon mot de passe');
  const [submitPasswordButtonTitle, setSubmitPasswordButtonTitle] = useState(type === 'new' ? 'Créer le mot de passe' : 'Modifier mon mot de passe');

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [currentPassword, setCurrentPassword] = useState('');
  const [currentPasswordCheckingLoading, setCurrentPasswordCheckingLoading] = useState(false);
  const [isCurrentPasswordCorrect, setIsCurrentPasswordCorrect] = useState(true);
  const [currentPasswordHelperText, setCurrentPasswordHelperText] = useState('');

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [passwordConfirmationHelperText, setPasswordConfirmationHelperText] = useState('');
  const [isMatchCorrect, setIsMatchCorrect] = useState(false);

  const [isReauthentSucessfull, setIsReauthentSucessfull] = useState(null);
  const [isPasswordUpdateSucessfull, setIsPasswordUpdateSucessfull] = useState(null);

  const [isPasswordCorrect, setIsPasswordCorrect] = useState(true);

  const [hasToVerifyToken, setHasToVerifyToken] = useState(true);
  const [step, setStep] = useState(0);
  const [loadingIndicator, setLoadingIndicator] = useState('Vérification..');

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const submitPassword = async () => {
    // call updateuserauth password firebase function
    const updateUserCreds = await updateFirebaseUserPassword(password, passwordConfirmation);
    if (updateUserCreds.code === 500 || updateUserCreds.code === 403) {
      setIsPasswordUpdateSucessfull(false);
      return false;
    } if (updateUserCreds.code === 401) {
      window.location.href = '/reconnect';
    } if (updateUserCreds.code === 201) {
      setStep(2);
      setIsPasswordUpdateSucessfull(true);
      setTimeout(() => {
        window.location.href = '/profile/me';
      }, 4000);
    } else {
      setIsPasswordUpdateSucessfull(false);
    }
    return false;
  };

  useEffect(() => {
    async function setUpAndClearSession() {
      const isSessionExpired = await checkUserToken();
      setHasToVerifyToken(false);
      if (isSessionExpired) {
        setStep(0);
        const email = await getUserEmail();
        localStorage.setItem('komeoReauthentMail', email);
        window.location.href = '/reconnect';
      } else if (step === 0) {
        setStep(1);
      }
    }
    if (open) {
      if (hasToVerifyToken) {
        setUpAndClearSession();
      }
    }
  }, [step, open]);

  useEffect(() => {
    const { status, message } = verifyCorrectPassword(currentPassword);
    if (status === false) {
      setCurrentPasswordHelperText(message);
    }
    setIsCurrentPasswordCorrect(status);
  }, [currentPassword]);

  useEffect(() => {
    const { status, message } = compareTwoInputs(password, passwordConfirmation);
    if (status === false) {
      setPasswordConfirmationHelperText(message);
    }
    setIsMatchCorrect(status);
  }, [password, passwordConfirmation]);

  useEffect(() => {
    if (step === 0) {
      setDialogTitle('Authentification nécessaire');
    } else {
      setDialogTitle(type === 'new' ? 'Nouveau mot de passe' : 'Modifier mon mot de passe');
      setSubmitPasswordButtonTitle(type === 'new' ? 'Créer le mot de passe' : 'Modifier mon mot de passe');
    }
  }, [step]);

  const handleReAuthenticateCaseOnClose = () => {
    // setStep(0);
    setLoadingIndicator('Vérification..');
    setCurrentPasswordCheckingLoading(false);
    setIsReauthentSucessfull(null);
    setCurrentPassword('');
    setPassword('');
    setPasswordConfirmation('');
    setIsPasswordUpdateSucessfull(null);
    onClose();
  };
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleReAuthenticateCaseOnClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className="feedback-alerts">
        {isReauthentSucessfull && (
          <FeedBackAlert type="success" sentence={[{ value: 'Authentification validée', bold: true }]} />
        )}
        {isReauthentSucessfull === false && (
          <FeedBackAlert type="error" sentence={[{ value: 'Mauvais mot de passe', bold: true }]} />
        )}
        {isPasswordUpdateSucessfull && (
          <FeedBackAlert type="success" sentence={[{ value: 'Le mot de passe a bien été modifié', bold: true }]} />
        )}
        {isPasswordUpdateSucessfull === false && (
          <FeedBackAlert type="error" sentence={[{ value: 'Un erreur est survenue, le mot de passe n\'a pas pu être modifié', bold: true }]} />
        )}
      </div>
      {step !== 2 && (
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
      )}
      {step === 1 && (
      <DialogContent>
        <Row className="justify-content-center">
          <Col xs="12" sm="12" md="12" lg="12" xl="12">
            <PasswordChecklist
              rules={['minLength', 'number', 'capital', 'match', 'specialChar']}
              minLength={8}
              value={password}
              valueAgain={passwordConfirmation}
              onChange={(isValid) => {
                setIsPasswordCorrect(isValid);
              }}
              messages={{
                minLength: 'Au moins 8 caractères.',
                number: 'Au moins un chiffre.',
                capital: 'Au moins une capitale.',
                match: 'Les deux champs doivent correspondre.',
                specialChar: 'Au moins un caractère spécial: ^ $ * [ { ( @ # % & / , < \' | _ ~ `.',
              }}
            />
            <FormControl fullWidth error={!isPasswordCorrect && !(password.length === 0)} sx={{ m: 1 }} variant="standard">
              <InputLabel htmlFor="standard-adornment-password">Mot de passe</InputLabel>
              <Input
                required
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                id="standard-adornment-password"
                type={showPassword ? 'text' : 'password'}
                aria-describedby="password-helper"
                endAdornment={(
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
              )}
              />
              {!isPasswordCorrect && (
              <FormHelperText id="password-helper">Un ou plusieurs critères ne sont pas respectés.</FormHelperText>
              )}
            </FormControl>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs="12" sm="12" md="12" lg="12" xl="12">
            <FormControl fullWidth error={!isMatchCorrect} sx={{ m: 1 }} variant="standard">
              <InputLabel htmlFor="standard-adornment-confirm-password">Confirmation du mot de passe</InputLabel>
              <Input
                required
                value={passwordConfirmation}
                onChange={(event) => setPasswordConfirmation(event.target.value)}
                id="standard-adornment-confirm-password"
                type={showConfirmPassword ? 'text' : 'password'}
                aria-describedby="confirm-password-helper"
                endAdornment={(
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownConfirmPassword}
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
              )}
              />
              {!isMatchCorrect && !(passwordConfirmation.length === 0) && (
              <FormHelperText id="confirm-password-helper">{passwordConfirmationHelperText}</FormHelperText>
              )}
            </FormControl>
          </Col>
        </Row>
        <Row className="justify-content-center mt-1">
          <Col xs="5" sm="5" md="5" lg="5" xl="5" className="d-flex justify-content-center">
            <Button
              variant="outlined"
              color="error"
              startIcon={<CloseIcon />}
              size="medium"
              onClick={handleReAuthenticateCaseOnClose}
            >
              Annuler
            </Button>
          </Col>
          <Col xs="7" sm="7" md="7" lg="7" xl="7" className="d-flex justify-content-center">
            <Button
              variant="outlined"
              size="medium"
              disabled={!isPasswordCorrect || !isMatchCorrect}
              onClick={submitPassword}
              color="success"
            >
              {submitPasswordButtonTitle}
            </Button>
          </Col>
        </Row>
      </DialogContent>
      )}
      {step === 2 && (
        <DialogContent>
          <Row>
            <Col xs="12">
              <Button
                onClick={handleReAuthenticateCaseOnClose}
                size="medium"
              >
                Fermer
              </Button>
            </Col>
          </Row>
        </DialogContent>
      )}
    </Dialog>
  );
}

DialogSetPassword.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  passwordUpdateDialogOpen: PropTypes.bool.isRequired,
};
