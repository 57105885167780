import * as Sentry from '@sentry/browser';
import { getApp } from 'firebase/app';
import {
  getFunctions,
  httpsCallable,
} from 'firebase/functions';
import { getAuth, updatePassword } from 'firebase/auth';

export async function getUserProfile() {
  const functions = getFunctions(getApp());
  const getuser = httpsCallable(functions, 'getuserprofile');
  const user = await getuser();
  return user.data.data;
}

export async function fetchAllFirebaseUsers() {
  const functions = getFunctions(getApp());
  const getallusers = httpsCallable(functions, 'getallusers');
  const users = await getallusers();
  return users.data.data;
}

export async function updateFirebaseUsers(type, users, customerAccount) {
  const functions = getFunctions(getApp());
  let data;
  if (type === 'update') {
    const updateusers = httpsCallable(functions, 'updateusers');
    const updateFeedback = await updateusers({ users, customerAccount });
    data = updateFeedback.data;
  } if (type === 'resetAuth') {
    const resetusersauth = httpsCallable(functions, 'resetuserauth');
    const resetFeedback = await resetusersauth({ users });
    data = resetFeedback.data;
  }
  return data;
}

export async function updateFirebaseUserPassword(password, passwordConfirmation) {
  if (password !== passwordConfirmation) {
    return { status: 403, message: 'passwords dont match.' };
  }
  const functions = getFunctions(getApp());
  const auth = getAuth();
  const user = auth.currentUser;
  let returned = false;
  if (user) {
    // update password
    try {
      await updatePassword(user, password).then(async () => {
        // update claims
        const updateuserauth = httpsCallable(functions, 'changecustomerclaims');
        const updateAuthMethodReq = await updateuserauth({ authMethod: 'password' });
        returned = { code: 201, updateAuthMethodReq };
      });
    } catch (error) {
      if (error.code === 'auth/requires-recent-login') {
        returned = { code: 401, message: 'need reauthenticate' };
        return returned;
      }
      Sentry.captureMessage(`in updateFirebaseUserPassword for user : ${JSON.stringify(user)}`);
      Sentry.captureException(error);
      return { code: 500, message: 'error', error };
    }
  } else return { code: 500, message: 'error' };
  return returned;
}

export async function API_LINK() {
  try {
    const url = 'https://getapilink-q22uw2353q-uc.a.run.app';
    const fireFuncReq = await fetch(
      url,
      {
        method: 'GET',
        mode: 'cors',
      },
    );
    if (fireFuncReq.status === 200) {
      const jsonFireFunc = await fireFuncReq.json();
      const serviceUrl = jsonFireFunc.data.graphService.url;
      return serviceUrl;
    } return 0;
  } catch (error) {
    return error;
  }
}

export async function getInitAuthLink() {
  let authLink = localStorage.getItem('initAuthLink');
  if (authLink) {
    return authLink;
  }
  const url = 'https://getinitauthlink-q22uw2353q-uc.a.run.app';
  const fireFuncReq = await fetch(
    url,
    {
      method: 'GET',
      mode: 'cors',
    },
  );
  if (fireFuncReq.status === 200) {
    const jsonFireFunc = await fireFuncReq.json();
    authLink = jsonFireFunc.data.link;
    return authLink;
  }
  return false;
}

// export async function fetchFirebaseFunctionsDashboardData() {
//   const functions = getFunctions(getApp());
//   // specify the komeoId to fetch data if necessary (admin case)
//   // const payload = { token: userToken };
//   // if (customerId !== null) { payload.customerId = customerId; }
//   const getDashboardData = httpsCallable(functions, 'getdashboarddata');
//   getDashboardData({ token: userToken, customerId })
//     .then((result) => {
//       handleDataReceived(result.data.data.userBasicData, result.data.data.userComputedSubData);
//     })
//     .catch((error) => {
//       Sentry.captureMessage(
//         `error ${error.details} : calling getdashboarddata firebase function.`,
//       );
//       Sentry.captureException(error);
//       if (Number.isInteger(error.details)) {
//         localStorage.setItem('KomeoServices', JSON.stringify({ status: error.details }));
//       }
//       switch (error.details) {
//         case 503:
//           alert('Notre service rencontre actuellement un problème,'
//               + 'veuillez réessayer plus tard.');
//           break;
//         case 500:
//           alert('Une erreur est survenue lors du traitement de votre demande,'
//             + 'si le problème persiste veuillez nous contacter');
//           break;
//         case 403:
//           alert('Vous n\'avez pas la permission d\'accéder à cette ressource,'
//               + 'veuillez vous reconnecter.');
//           break;
//         case 404:
//           alert('Impossible d\'accéder à la ressource demandée, vous allez être redirigé.');
//           break;
//         case 401:
//           alert('Le service actuellement indisponible, notre
// équipe technique a été notifiée de la situation.');
//           break;
//         default:
//           alert('Une erreur inconnue est survenue, si le problème persiste
// veuillez nous contacter.');
//           break;
//       }
//       clearSession();
//     });
// };
