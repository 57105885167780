/* eslint-disable react/prop-types */
import React from 'react';

import videoHigh from '../../assets/images/contents/videoHigh.svg';
import videoFlash from '../../assets/images/contents/videoFlash.svg';
import visuel from '../../assets/images/contents/visuel.svg';
import videoSnack from '../../assets/images/contents/videoSnack.svg';
import shootingPhoto from '../../assets/images/contents/shootingPhoto.svg';
import publication from '../../assets/images/contents/publication.svg';

import demandeAide from '../../assets/images/toolbox/demande_aide.svg';
import facture from '../../assets/images/toolbox/facture.svg';
import dossierCreatif from '../../assets/images/toolbox/dossier_creatif.svg';
import demandeContenu from '../../assets/images/toolbox/demande_contenu.svg';
import news from '../../assets/images/toolbox/news.svg';
import bugReport from '../../assets/images/toolbox/bug_report.svg';

import defaultIcone from '../../assets/images/toolbox/demandeContenu.svg';

function CardImage({ imgType }) {
  const contentTypeSize = 70;
  const toolboxSize = 35;
  const svg = {
    file: '',
    size: 70,
  };
  switch (imgType) {
    case 'Vidéo High':
      svg.file = videoHigh;
      svg.size = contentTypeSize;
      break;
    case 'Vidéo Flash':
      svg.file = videoFlash;
      svg.size = contentTypeSize;
      break;
    case 'Visuel':
      svg.file = visuel;
      svg.size = contentTypeSize;
      break;
    case 'Vidéo Snack':
      svg.file = videoSnack;
      svg.size = contentTypeSize;
      break;
    case 'Photographie':
      svg.file = shootingPhoto;
      svg.size = contentTypeSize;
      break;
    case 'Publication':
      svg.file = publication;
      svg.size = contentTypeSize;
      break;
    case 'demande_aide':
      svg.file = demandeAide;
      svg.size = toolboxSize;
      break;
    case 'facture':
      svg.file = facture;
      svg.size = toolboxSize;
      break;
    case 'dossier_creatif':
      svg.file = dossierCreatif;
      svg.size = toolboxSize;
      break;
    case 'demande_contenu':
      svg.file = demandeContenu;
      svg.size = toolboxSize;
      break;
    case 'news':
      svg.file = news;
      svg.size = toolboxSize;
      break;
    case 'bug_report':
      svg.file = bugReport;
      svg.size = toolboxSize;
      break;
    default:
      svg.file = defaultIcone;
      svg.size = 45;
      break;
  }
  return <img src={svg.file} alt={imgType} width={svg.size} />;
}
export default CardImage;
