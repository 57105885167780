/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import { React, useEffect } from 'react';
import { Col, Row } from 'reactstrap';

import './style.css';
import jpegLoader from '../../assets/images/loader/komeo.jpg';

function Loader(props) {
  useEffect(() => {
    if (props.loading) {
      document.querySelector('#loader-container').style.display = 'flex';
      document.querySelector('#app-content').style.display = 'none';
    } else {
      document.querySelector('#loader-container').style.display = 'none';
      document.querySelector('#app-content').style.display = 'block';
    }
  }, [props.loading]);

  return (
    <Row id="loader-container" className="vh-100 vw-100">
      <Col className="d-flex flex-column justify-content-center align-items-center">
        <img id="loader-img" src={jpegLoader} alt="logo komeo - chargement de la page en cours" />
        <p>Chargement..</p>
      </Col>
    </Row>
  );
}
export default Loader;
