export function verifyCorrectPassword(password) {
  if (password.length < 8) {
    return { status: false, message: 'Le mot de passe est trop court: au moins 8 caractères.' };
  } return { status: true };
}

export function compareTwoInputs(first, second) {
  if (first === second) {
    return { status: true };
  } return { status: false, message: 'Sans utiliser de copier / coller.' };
}
