/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkIcon from '@mui/icons-material/Link';
import { createTheme } from '@mui/material/styles';

import { Col, Row } from 'reactstrap';

// import './style.css';
import { Alert, AlertTitle } from '@mui/material';

import { updateFirebaseUsers } from '../../../utils/api/firebase-functions';

// const theme = createTheme({
//   status: {
//     danger: '#e53e3e',
//   },
//   palette: {
//     primary: {
//       main: '#0971f1',
//       darker: '#053e85',
//     },
//     neutral: {
//       main: '#64748B',
//       contrastText: '#fff',
//     },
//   },
// });

export default function DialogUsersValidation({
  type, title, selected, customerAccount,
}) {
  const [open, setOpen] = React.useState(false);
  const [feedback, setFeedBack] = React.useState([]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = async () => {
    try {
      const feedbackReq = await updateFirebaseUsers(type, selected, customerAccount);
      if (feedbackReq.status === 200) {
        alert('Opération réalisée avec succès');
      }
    } catch (error) {
      alert('une erreur est survenue.');
    }
    window.location.reload();
    // setFeedBack(feedbackReq.data.feedback);
  };

  return (
    <div>
      {feedback.length > 0 && feedback.forEach((userFeedback) => userFeedback.status)}
      {type === 'update'
        && (
          <Tooltip title="Valider">
            <IconButton className="mx-1" onClick={handleClickOpen} disabled={customerAccount === ''}>
              <DoneIcon />
            </IconButton>
          </Tooltip>
        )}
      {type === 'delete'
        && (
          <Tooltip title="Supprimer">
            <IconButton className="mx-1" onClick={handleClickOpen}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      {type === 'resetAuth'
        && (
          <Tooltip title="Réinitialiser authentification">
            <IconButton className="mx-1" onClick={handleClickOpen}>
              <LinkIcon />
            </IconButton>
          </Tooltip>
        )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {type === 'update' && `Confirmer l'association des éléments suivants à ${customerAccount}:`}
            {type === 'delete' && 'Confirmer la suppression du compte de:'}
            {type === 'resetAuth' && 'Confirmer la réinitialisation de la méthode de connexion (par lien unique) de:'}
            <Row className="px-2">
              {selected.map((item) => (<Col xs="12" key={item.uid}>{item.mail}</Col>))}
            </Row>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleClose} autoFocus>Annuler</Button>
          <Button color="success" onClick={handleUpdate}>Valider</Button>
        </DialogActions>
        {/* deletion actions */}
        {/* <DialogActions>
          <Button color="error" onClick={handleClose}>Supprimer</Button>
          <Button color="inherit" onClick={handleClose} autoFocus>Annuler</Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}
DialogUsersValidation.defaultProps = {
  selected: [],
};
DialogUsersValidation.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  selected: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string,
      mail: PropTypes.string,
    }),
  ),
  customerAccount: PropTypes.string.isRequired,
};
