/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/named */
/* eslint-disable quotes */
/* eslint-disable import/order */
/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable object-curly-spacing */
import React, {useState} from 'react';
import ReactDOM from 'react-dom/client';
// import { blueGrey } from '@mui/material/colors';
import { grey } from '@mui/material/colors';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import * as Sentry from "@sentry/react";

// core style
import './style/assets/fonts/feather/iconfont.css';
import './style/scss/core.scss';
import './style/assets/scss/style.scss';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import { Provider } from 'react-redux';
// eslint-disable-next-line import/named
// import { store } from './redux/store';

import App from './App';
import reportWebVitals from './reportWebVitals';
// import theme from './theme';

const theme = createTheme({
  palette: {
    primary: {
      main: grey[900],
    },
    secondary: {
      main: '#f44336',
    },
  },
});
const queryClient = new QueryClient();

Sentry.init({
  dsn: "https://32b0f2873f25489bb8c0726a77609a6f@o1428790.ingest.sentry.io/4505481471918080",
  integrations: [
    new Sentry.BrowserTracing({
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "https://my.komeocreation.fr"],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  // This sets the sample rate at 10%. You may want to change it to 100% while in
  // development and then sample at a lower rate in production.
  replaysSessionSampleRate: 1,
  // If you're not already sampling the entire session, change the sample rate to
  // 100% when sampling sessions where errors occur.
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById('root')).render(
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        {/* <Provider store={store}> */}
                <BrowserRouter>
                    <div id="app-content">
                        <App />
                    </div>
                </BrowserRouter>
        {/* </Provider> */}
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ThemeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
