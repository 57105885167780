/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import {
  React,
  useEffect, useState,
} from 'react';
import * as Sentry from '@sentry/browser';
// import { useHistory } from "react-router-dom";
import {
  Button, CardText, Col, Label, Row, FormFeedback, Input, FormGroup,
} from 'reactstrap';
import {
  setPersistence, signInWithEmailAndPassword, sendSignInLinkToEmail,
  browserLocalPersistence, sendPasswordResetEmail, getAuth,
  isSignInWithEmailLink, signInWithEmailLink,
} from 'firebase/auth';
import isEmail from 'validator/es/lib/isEmail';
import { getApp } from 'firebase/app';
import {
  getFunctions,
  httpsCallable,
  // connectFunctionsEmulator,
} from 'firebase/functions';
import Loader from '../../Loader';

import headerLogo from '../../../assets/images/header/logo-header-black.png';
import animation from '../../../assets/animations/schema_export_003.gif';

import './style.css';
import getFirebaseInstance from '../../../firebase';

import { getInitAuthLink } from '../../../utils/api/firebase-functions';
import clearSession from '../../../utils/clearSession';

export default function Login() {
  // ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const [darkAlert, setDarkAlert] = useState(false);
  const [darkAlertMessage, setDarkAlertMessage] = useState('');
  const [resetMailSuccess, setResetMailSuccess] = useState(false);
  const [resetMailFail, setResetMailFail] = useState(false);
  const [resetMailFailCount, setResetMailFailCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loginSuccesMessage, setLoginSuccesMessage] = useState('Un mail de réinitialisation de mot de passe a été envoyé à l\'adresse indiquée.');

  const [step, setStep] = useState(0);

  const [firebaseSingleton, setFirebaseSingleton] = useState(null);
  const [firebaseSingletonState, setFirebaseSingletonState] = useState(null);
  const [auth, setAuth] = useState(false);

  const [fireLoginState, setFireLoginState] = useState(false);

  //    front form verification
  const [disabledSubmitButtonState, setDisabledSubmitButtonState] = useState(true);
  const [submitButtonText, setSubmitButtonText] = useState('Se connecter');

  // if (window.location.pathname !== '/') {
  //   window.location.pathname = '/';
  // }

  const resetFeedbacks = () => {
    setDarkAlert(false);
    setResetMailSuccess(false);
    setResetMailFail(false);
  };

  useEffect(() => {
    // Récupérez l'instance de Firebase de manière asynchrone
    getFirebaseInstance().then((instance) => {
      setFirebaseSingleton(instance);
      setFirebaseSingletonState(true);
    });
  }, []);

  useEffect(() => {
    if (firebaseSingletonState) {
      const localAuth = getAuth();
      setAuth(localAuth);
    }
  }, [firebaseSingleton]);

  useEffect(() => {
    // if (!(password.length >= 6)) {
    //   setDisabledSubmitButtonState(true);
    // } else
    if (isEmail(id)) {
      setDisabledSubmitButtonState(false);
    } else setDisabledSubmitButtonState(true);
  }, [id, password]);

  function displayMailFeedBack() {
    if (!isEmail(id)) {
      document.querySelector('#mail').setAttribute('aria-invalid', 'true');
      document.querySelector('#mail').classList.add('is-invalid');
      return false;
    }
    document.querySelector('#mail').setAttribute('aria-invalid', 'false');
    if (document.querySelector('#mail').classList.contains('is-invalid')) {
      document.querySelector('#mail').classList.remove('is-invalid');
      document.querySelector('#mail').classList.add('is-valid');
    }
    return true;
  }

  useEffect(() => {
    // DIRTY did mount
    if (id.length >= 1) {
      if (isEmail(id)) {
        document.querySelector('#mail').setAttribute('aria-invalid', 'false');
        if (document.querySelector('#mail').classList.contains('is-invalid')) {
          document.querySelector('#mail').classList.remove('is-invalid');
          document.querySelector('#mail').classList.add('is-valid');
        }
      }
    }
  }, [id]);

  useEffect(() => {
    //    check if a session is already up
    const localStorageSession = JSON.parse(window.localStorage.getItem('KomeoSession'));
    if (localStorageSession?.admin) { localStorage.removeItem('KomeoSession'); } else if (localStorageSession) {
      if (localStorageSession.customerAccount === 'Komeadmin') {
        window.location.href = '/admin/customers';
      } else {
        window.location.href = '/dashboard/me';
      }
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (firebaseSingletonState === 'testing') {
      setPersistence(auth, 'none')
        .catch((error) => {
          Sentry.captureMessage('error in setPersistence in Login.jsx');
          Sentry.captureException(error);
        });
    }
  }, [firebaseSingletonState]);

  const badCredsMessage = 'Connexion impossible - Veuillez vérifier votre identifiant et votre mot de passe.';
  const unavailableServiceMessage = 'Un de nos services n\'est pas disponible, veuillez réessayer plus tard.';
  const resetFailMessage = [
    'Une erreur est survenue lors de l\'envoie du mail, veuillez vérifier que votre adresse mail est correctement écrite.',
    'Si le problème persiste, veuillez contacter', 'mathieu@komeocreation.fr', ' l\'équipe technique.'];

  const actionCodeSettings = {
    // url: 'http://localhost:3000/',
    url: `${window.location.origin}`,
    handleCodeInApp: true,
  };

  const displaySignInMailSendState = (state) => {
    if (state === 'success') {
      setLoginSuccesMessage('Un mail a été envoyé à l\'adresse indiquée si un compte est lié à celle-ci.');
      setResetMailSuccess(true);
      setDisabledSubmitButtonState(true);
      setSubmitButtonText('Email de connexion envoyé');
      setTimeout(() => {
        setSubmitButtonText('Se connecter');
        setDisabledSubmitButtonState(false);
        setId('');
      }, 7000);
    } else if (state === 'fail') {
      setDarkAlertMessage("Une erreur est survenue lors de l'envoie du mail, vérifiez votre adresse email.");
      setDarkAlert(true);
      setDisabledSubmitButtonState(true);
    }
  };

  const handleMailLinkSignIn = async () => {
    if (firebaseSingleton) {
      sendSignInLinkToEmail(auth, id, actionCodeSettings)
        .then(() => {
          localStorage.setItem('emailForSignIn', id);
          displaySignInMailSendState('success');
          return true;
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          displaySignInMailSendState('fail');
          Sentry.captureMessage(`Un mail d'authentification ne s'est pas envoyé sur ${window.location.href}. Mail en question : ${id}.`);
          return false;
        });
    }
  };

  async function signIn() {
    if (firebaseSingleton) {
      signInWithEmailAndPassword(
        auth,
        id,
        password,
      )
        .then((userCredential) => {
          setFirebaseSingletonState('testing');
          let token = false;
          // eslint-disable-next-line no-unused-vars
          token = auth.currentUser.accessToken;
          auth.currentUser.getIdTokenResult(false).then((idTokenResult) => {
            token = idTokenResult;
            // eslint-disable-next-line no-console
            const customerClaims = idTokenResult.claims;
            const session = {
              token: parseInt(customerClaims.komeo_token, 10),
              // admin: customerClaims.admin,
              customerAccount: customerClaims.customerAccount,
              id: customerClaims.komeo_id,
              mail: customerClaims.email,
            };
            localStorage.setItem('KomeoSession', JSON.stringify(session));
            if (idTokenResult.claims.customerAccount === 'Komeadmin') {
              window.location.href = '/admin/customers';
            } else {
              window.location.href = '/dashboard/me';
            }
          });
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          resetFeedbacks();
          setDarkAlert(true);
          setDarkAlertMessage(badCredsMessage);
          Sentry.captureMessage('error in signInWithEmailAndPassword in Login.jsx');
          Sentry.captureException(error);
          return { errorCode, errorMessage };
        });
    }
  }

  async function submitMail() {
    try {
      const url = await getInitAuthLink();
      if (url === false) {
        Sentry.captureMessage('getInitAuthLink returned false');
        resetFeedbacks();
        setDarkAlertMessage("Une erreur est survenue, l'accès à votre espace est momentanément impossible.");
        setDarkAlert(true);
        // todo: utiliser le constructor de la doc
        throw new Error('firebase functions service fails @ getinitauthlink.');
      }
      const fireFuncReq = await fetch(
        url,
        {
          method: 'POST',
          mode: 'cors',
          body: JSON.stringify({ mail: id }),
        },
      );
      resetFeedbacks();
      if (fireFuncReq.status === 200) {
        // if the req is successfull, set the link in localstorage
        localStorage.setItem('initAuthLink', url);
        const jsonFireFunc = await fireFuncReq.json();
        const { authMethod } = jsonFireFunc;
        if (authMethod === 'link') {
          const linkAuthReq = await handleMailLinkSignIn();
          if (linkAuthReq === true) {
            setLoginSuccesMessage('Un mail a été envoyé à l\'adresse indiquée si un compte est lié à celle-ci.');
            setResetMailSuccess(true);
            setDisabledSubmitButtonState(true);
            setSubmitButtonText('Email de connexion envoyé');
            setTimeout(() => {
              setSubmitButtonText('Se connecter');
              setDisabledSubmitButtonState(false);
              setId('');
            }, 7000);
          }
        } else if (authMethod === 'password') {
          setStep(1);
        }
        return true;
      } if (fireFuncReq.status === 404) {
        setDarkAlertMessage('Aucun utilisateur ne correspond à cette adresse mail.');
        document.querySelector('#mail').setAttribute('aria-invalid', 'true');
        document.querySelector('#mail').classList.add('is-invalid');
      } else {
        setDarkAlertMessage('Une erreur est survenue lors de l\'envoie, veuillez réessayer plus tard.');
      }
      setDarkAlert(true);
      Sentry.captureMessage(`firebase function initauth: ${fireFuncReq.status} status. With email ${id}`);
      Sentry.captureException(fireFuncReq);
      return false;
    } catch (error) {
      Sentry.captureMessage('submitMail error');
      Sentry.captureException(error);
      return false;
    }
  }

  useEffect(() => {
    if (fireLoginState) {
      if (step === 0) {
        submitMail();
      } else if (step === 1) {
        try {
          resetFeedbacks();
          signIn();
          // loginWithoutPassword();
          // submitMail();
          // setStep(step + 1);
        } catch (error) {
          Sentry.captureMessage('error in signInTest in Login.jsx');
          Sentry.captureException(error);
        }
      }
      setFireLoginState(false);
    }
  }, [fireLoginState]);

  const handleResetPassword = () => {
    resetFeedbacks();
    if (displayMailFeedBack()) {
      if (firebaseSingleton) {
        // const { auth } = firebaseSingleton;
        const mail = id;
        sendPasswordResetEmail(auth, mail)
          .then(() => {
            resetFeedbacks();
            setResetMailSuccess(true);
          })
          .catch((error) => {
            resetFeedbacks();
            setResetMailFailCount(resetMailFailCount + 1);
            setResetMailFail(true);
            Sentry.captureMessage('error in sendPasswordResetEmail in Login.jsx');
            Sentry.captureException(error);
          });
      }
    }
  };

  useEffect(() => {
    if (auth !== false) {
      if (isSignInWithEmailLink(auth, window.location.href)) {
        let email = localStorage.getItem('emailForSignIn');
        // let email = localStorage.getItem('emailForSignIn');
        if (!email) {
          email = window.prompt('veuillez renseigner votre adresse mail');
        }
        signInWithEmailLink(auth, email, window.location.href)
          .then((result) => {
            window.localStorage.removeItem('emailForSignIn');
            // get users' claims
            // todo: refacto with async function signIn() {
            result.user.getIdTokenResult()
              .then(
                (idTokenResult) => {
                  const customerClaims = idTokenResult.claims;
                  const komeoSession = {
                    token: parseInt(customerClaims.komeo_token, 10),
                    // admin: customerClaims.admin,
                    customerAccount: customerClaims.customerAccount,
                    id: customerClaims.komeo_id,
                    mail: customerClaims.email,
                  };
                  window.localStorage.setItem('KomeoSession', JSON.stringify(komeoSession));
                  if (customerClaims.customerAccount === 'Komeadmin') {
                    window.location.href = '/admin/customers';
                  } else {
                    window.location.href = '/dashboard/me';
                  }
                },
              );
          }).catch((error) => {
            alert('Une erreur est survenue, vous allez être redirigé.');
            clearSession(null, '/');
            Sentry.captureMessage(`erreur sur ${window.location.origin}${window.location.pathname} lors de signInWithEmailLink avec ${email}`);
            Sentry.captureException(error);
          });
      }
    }
  }, [auth]);

  if (!firebaseSingletonState) {
    return (
      <Loader loading />
    );
  }

  if (localStorage.getItem('KomeoServices')) {
    const komeoServices = localStorage.getItem('KomeoServices');
    const komeoServicesStatus = JSON.stringify(komeoServices).status;
    if (komeoServicesStatus === 403) {
      resetFeedbacks();
      setDarkAlert(true);
      setDarkAlertMessage(unavailableServiceMessage);
    }
  }

  return (
    <>
      <Loader loading={loading} />
      <div id="app-content" style={{ height: '100vh' }}>
        <div id="app-login-container">
          {darkAlert
                        && (<h4 className="alert-dark p-1 m-1">{darkAlertMessage}</h4>)}
          {resetMailSuccess
                        && (<h4 className="alert-success p-1 m-1">{loginSuccesMessage}</h4>)}
          {resetMailFail
                        && (
                          <h4 className="alert-danger p-1 m-1">
                              {resetFailMessage[0]}
                              {resetMailFailCount > 2 && (
                              <h5>
                                {resetFailMessage[1]}
                                <a style={{ color: '#ea5455', fontWeight: 'bold' }} href={`mailto:${resetFailMessage[2]}`}>
                                  {resetFailMessage[3]}
                                </a>
                              </h5>
                              )}
                          </h4>
                        )}
          {/* <h4 className="alert-danger p-1 m-1">
            MAINTENANCE -
            Une mise à jour est en cours ce matin, le service est momentanément indisponible.
            <h5>

              <a style={{ color: '#ea5455', fontWeight: 'bold' }} />
            </h5>
          </h4> */}
          <Row className="auth-inner m-0 justify-content-center align-items-center full-height">
            <Col
              id="form-container-col"
              sm="12"
              xl="11"
              xxl="11"
              className="d-flex align-items-center justify-content-center auth-bg px-2 p-lg-5"
            >
              <div id="login-box" className="d-flex justify-content-center flex-column align-items-center">
                <img id="login-komeo-logo" src={headerLogo} alt="Logo Komeo" />
                <CardText className="mb-1 mt-1 text-center welcome-message">
                  Connectez vous pour accéder à votre espace
                </CardText>
                <div className="auth-login-form">
                  <div className="d-flex flex-column align-items-center">
                    <Label className="form-label" for="login-email">
                      Mail
                    </Label>
                    <FormGroup>
                      <Input
                        // valid
                        // className="login-input "
                        type="text"
                        value={id}
                        id="mail"
                        className="full-width"
                        style={{ with: '100%' }}
                        onChange={(event) => setId(event.target.value)}
                        disabled={step === 1}
                      />
                      <FormFeedback>
                        L&apos;adresse mail n&apos;est pas correcte.
                      </FormFeedback>
                    </FormGroup>
                  </div>
                  {step === 1 && (
                    <>
                      <div className="mb-1 d-flex flex-column align-items-center">
                        <Label className="form-label" for="login-email">
                          Mot de passe
                        </Label>
                        <FormGroup>
                          <Input
                          // className="login-input"
                            className="full-width"
                            type="password"
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                          />
                        </FormGroup>
                      </div>
                      <div className="mb-1 cursor-pointer text-center">
                        <button
                          type="button"
                          className="text-decoration-underline"
                          onClick={handleResetPassword}
                        >
                          mot de passe oublié
                        </button>
                      </div>
                    </>
                  )}
                  <Row className="d-flex justify-content-center mb-1">
                    <Col xs="7">
                      {/* <Button disabled color="dark" style={{ cursor: 'pointer' }} block> */}
                      <Button disabled={disabledSubmitButtonState} onClick={() => setFireLoginState(true)} color="dark" style={{ cursor: 'pointer' }} block>
                        {submitButtonText}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
              <img id="login-komeo-animation" src={animation} alt="processus Komeo" />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
