/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { Button, Col, Row } from 'reactstrap';
import CardImage from '../CardImage';
import CardSnippet from '../CardSnippet';

// import '../style/components/Toolbox.css';
import './style.css';

function ToolboxButton(props) {
  return (
    <>
      {/* //todo : Using target="_blank" without rel="noreferrer" */}
      <a
        className={props.disabled ? 'disabled width-toolbox-item' : 'width-toolbox-item'}
        href={props.link}
        target="_blank"
        rel="noreferrer"
      >
        {/* todo : passer par une div, arrêter d'utiliser un composant d'une dépendance */}
        <Button
          key={props.id}
          className="btn-icon toolbox-btn d-flex full-width justify-content-center"
          outline
          color="dark"
          disabled={props.disabled}
        >
          <CardImage imgType={props.path} />
          <div className="align-self-center full-width text-start ps-1">{props.name}</div>
        </Button>
      </a>
    </>
  );
}

function Toolbox(props) {
  const buttons = [
    {
      id: 5,
      name: 'NEWS',
      image_path: 'news',
      link: 'https://komeocreation.fr/blog-komeo/',
      active: true,
    },
    {
      id: 2,
      name: 'FACTURES',
      image_path: 'facture',
      link: props.invoicesDirectory,
      active: false,
    },
    {
      id: 1,
      name: "J'AI UNE DEMANDE",
      image_path: 'demande_aide',
      link: `mailto:${props.referent.mail},antony@komeocreation.fr
            ?subject=DEMANDE D'AIDE de la part de ${props.clientName}
            &body=Bonjour ${props.referent.firstName},`,

      active: false,
    },
    {
      id: 3,
      name: 'DOSSIER CRÉATIF',
      // todo :
      // image: require('../assets/images/toolbox/bug_report.svg'),
      image_path: 'dossier_creatif',
      link: props.creativeDirectory,
      active: true,
    },
  ];
  return (
    <Row style={{ flex: 1 }}>
      <Col
        sm="12"
        id="toolbox-card-snippet"
      >
        <CardSnippet
          title="Boite à outils"
          subtitle="Tous les raccourcis pratique de mon espace Komeo"
          iconCode=" "
        >
          {/* todo: faire un <ToolboxButtons /> ? */}
          <div
            className="d-flex flex-wrap justify-content-start toolbox-demo-inline-spacing"
          >
            <Row id="toolbox-buttons-wrapper">
              {buttons.map((buttonItem) => (
                <Col sm="6" xs="6" className="mt-1" key={buttonItem.id}>
                  <div
                    className="d-flex justify-content-center toolbox-buttons-container"
                  >
                    <ToolboxButton
                      id={buttonItem.id}
                      name={buttonItem.name}
                      path={buttonItem.image_path}
                      link={buttonItem.link}
                      disabled={buttonItem.link.length <= 0}
                    />
                  </div>
                </Col>
              ))}
            </Row>

          </div>
        </CardSnippet>
      </Col>
    </Row>
  );
}
export default Toolbox;
