/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import { Col, Row } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import * as Icons from 'react-feather';
import LogOut from '../LogOut';

import './style.css';

import headerLogo from '../../assets/images/header/logo-header-black-noborder.png';
import BackNavigationButton from '../BackNavigationButton';
// import headerLogo from '../../assets/images/header/logo-header-black.png';
// import retourClient from '../../assets/images/header/retour-client.png';

function getRowClasses(isAdmin, showNavigation) {
  // const adminWithoutNavigation = !showNavigation && isAdmin;
  // const adminWithNavigation = showNavigation && isAdmin;
  // const nonAdmin = !isAdmin;
  if (!isAdmin) {
    return 'justify-content-between full-width-marge align-items-start';
  } if (showNavigation) {
    // à revérifier
    return 'justify-content-between';
  }
  return 'justify-content-end mx-0 mt-1 align-items-center';
}

function Header(props) {
  const SMALL_DEVICE_CONDITIONAL_VALUE = 768;

  const { ChevronDown } = Icons;
  const { ChevronUp } = Icons;
  const { User } = Icons;

  const uri = window.location.pathname;
  let showBackNavigationButton = true;
  let isSmallAtRender = false;

  const session = JSON.parse(localStorage.getItem('KomeoSession'));

  if (window.innerWidth <= SMALL_DEVICE_CONDITIONAL_VALUE) {
    isSmallAtRender = true;
  }

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSmallDevice, setIsSmallDevice] = useState(isSmallAtRender);

  if (uri === '/admin/customers') {
    showBackNavigationButton = false;
  }

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= SMALL_DEVICE_CONDITIONAL_VALUE) {
        setIsSmallDevice(true);
      } else {
        setIsSmallDevice(false);
      }
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    // <Row id="header-sticky" className="full-width full-width-marge d-flex
    // justify-content-between align-items-center mb-1">
    <Row id="header-sticky" className={`full-width d-flex ${getRowClasses(props.admin, showBackNavigationButton)} mb-1 mt-1`}>
      {props.admin && showBackNavigationButton
        && (
          <Col>
            <BackNavigationButton />
          </Col>
        )}
      {!props.admin && (
        <Col xs="6" sm="6" md="4" lg="4" xl="4" className={(!isDropdownOpen && !isSmallDevice) && 'mb-2'}>
          <div className="d-flex justify-content-center">
            <img id="header-logo" src={headerLogo} alt="logo komeo - chargement de la page en cours" />
          </div>
        </Col>
      )}
      <Col xs="3" sm="4" md="6" lg="6" xl="5" className="d-flex justify-content-center mx-2">
        <div
          id="profile"
          className={`d-flex justify-content-center flex-column ${(!isDropdownOpen && !isSmallDevice) && 'mb-1'}`}
          onClick={toggleDropdown}
        >
          <div id="profile-header" className="d-flex justify-content-center align-items-center">
            {isSmallDevice ? (
              <User size={36} />
            )
              : (
                <div>
                  {`${session.customerAccount} (${session.mail})`}
                  {isDropdownOpen ? (<ChevronUp />) : (<ChevronDown />)}
                </div>
              ) }
          </div>
          {isDropdownOpen && (
            <div id="profile-navigation" className="d-flex flex-column align-items-center">
              <div className="cursor-pointer" onClick={() => { window.location.href = '/profile/me'; }}>Compte</div>
              <LogOut showIcon={false} auth={props.auth} />
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
}
export default Header;
