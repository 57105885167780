/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes, { string } from 'prop-types';
import { isUserAdmin } from '../../utils/api/firebase-auth';

export default function BackNavigationButton({ targetUri }) {
  const [isAdmin, setIsAdmin] = useState(null);
  const [getCustomerAccountState, setGetCustomerAccountState] = useState(false);
  // 1. savoir si l'utilisateur est admin avant d'assigner une uri autre que '/'
  const [uri, setUri] = useState('/');

  useEffect(() => {
    const getIsAdmin = async () => {
      const result = await isUserAdmin();
      if (typeof result === 'boolean') {
        setIsAdmin(result);
        setGetCustomerAccountState(true);
      }
    };
    if (isAdmin === null) {
      getIsAdmin();
    }
  }, []);

  useEffect(() => {
    if (getCustomerAccountState) {
      // 2. si une targetUri est donnée, vérifier si l'utilisateur actuel a le droit d'y accéder
      if (targetUri !== undefined) {
        if (targetUri.includes('admin') && !isAdmin) {
          setUri('/dashboard/me');
        } else setUri(targetUri);
      } else {
        // 3. sinon récupérer le path de la dernière page consultée, comparer avec l'actuelle
        const lastPageUrl = new URL(document.referrer);
        const lastPagePath = lastPageUrl.pathname;
        const isLastPageDifferent = lastPagePath !== window.location.pathname;
        // 4. si la dernière page est bien différente de l'actuelle, l'assigner à uri
        if (isLastPageDifferent) {
          setUri(lastPagePath);
        } else if (isAdmin) {
          // 5. sinon si l'utilisateur est admin renvoyer au menu backoffice
          setUri('/admin/customers');
        } else {
          // 6. enfin renvoyer l'utilisateur sur son dashboard
          setUri('/dashboard/me');
        }
      }
    }
  }, [getCustomerAccountState]);

  return (
    <div className="menu-button justify-content-start">
      <Link to={uri}>
        &lt; Retour
      </Link>
    </div>
  );
}

BackNavigationButton.defaultProps = {
  targetUri: undefined,
};

BackNavigationButton.propTypes = {
  targetUri: PropTypes.string,
};
