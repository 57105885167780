/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

import './style.css';
import { Alert, AlertTitle } from '@mui/material';

function FeedBackAlert({ type, sentence = [] }) {
  // set default bold to false
  const renderedSentence = React.useMemo(
    () => sentence.map((part) => ({
      ...part,
      bold: part.bold === undefined ? false : part.bold,
    })),
    [sentence],
  );

  const renderSentence = React.useCallback(() => {
    const lastIndex = renderedSentence.length - 1;
    return renderedSentence.map((part, index) => (
      <React.Fragment key={index}>
        {part.bold ? <strong>{part.value}</strong> : part.value}
        {index < lastIndex && ' '}
      </React.Fragment>
    ));
  }, [renderedSentence]);

  return (
    <Alert severity={type} className="m-2">
      <AlertTitle className="feedback-alert-title">
        {renderSentence()}
        .
      </AlertTitle>
    </Alert>
  );
}

FeedBackAlert.defaultProps = {
  sentence: [{ value: '', bold: false }],
};

FeedBackAlert.propTypes = {
  type: PropTypes.string.isRequired,
  sentence: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      bold: PropTypes.bool,
    }),
  ),
};
export default FeedBackAlert;
