import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
// import { getAuth } from 'firebase/auth';

let app;

const getFirebaseInstance = async () => {
  // Si l'instance de Firebase a déjà été initialisée, renvoyez-la

  const localStorageSession = localStorage.getItem('KomeoFirebaseInstance');

  if (app) {
    return app;
  } if (localStorageSession) {
    return JSON.parse(localStorageSession);
  }

  // Récupérez la configuration de Firebase de manière asynchrone
  const configResponse = await fetch(
    'https://getfirebaseconfigv2-q22uw2353q-uc.a.run.app',
    { method: 'GET', mode: 'cors' },
  );
  const config = await configResponse.json();

  // Initialisez votre instance de Firebase avec la configuration récupérée
  app = firebase.initializeApp(config);

  // const auth = getAuth(app);

  // localStorage.setItem('KomeoFirebaseInstance', JSON.stringify(app));
  // return { firebaseInstance: app, auth };
  return app;
};

export default getFirebaseInstance;
