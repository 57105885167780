/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';

import * as Sentry from '@sentry/browser';

import { getFunctions, httpsCallable } from 'firebase/functions';
import { createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';

import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import {
  Button, TextField, FormControl, InputLabel, MenuItem, Select,
} from '@mui/material';

import './style.css';

function AddUserForm({
  clientList, password, authSession,
  setUserCreatedAlertStatus, assignUserCreatedAlertObject,
}) {
  // form states
  const [signupMail, setSignupMail] = useState('');
  const [customerAccountSelected, setCustomerAccountSelected] = useState('');
  const [addButtonState, setAddButtonState] = useState(false);

  useEffect(() => {
    async function setCustomerRole(mail, customerAccount) {
      try {
        const functions = getFunctions();
        const getCallableFunction = httpsCallable(functions, 'setcustomerrole');
        getCallableFunction({ mail, customerAccount })
          .then((result) => {
            if (result.data.status !== 201) {
              Sentry.captureMessage('non 201 from setCustomerRole');
              Sentry.captureException(result.data);
              throw new Error({
                cause: `${result.data.status} response from firebase function`,
                message: 'failed to assign role to user.',
                code: 'failed-set-customer-role',
              });
            }
          })
          .catch((error) => {
            Sentry.captureMessage('erreur dans l\'appel de setCustomerRole');
            Sentry.captureException(error);
          });
      } catch (error) {
        Sentry.captureMessage('erreur dans setCustomerRole');
        Sentry.captureException(error);
      }
    }
    // eslint-disable-next-line no-unused-vars
    async function signUp(_auth) {
      // createUserWithEmailAndPassword(
      //   _auth,
      //   signupMail,
      //   password,
      // )
      //   .then((userCredential) => {
      //     // Signed in
      //     const { user } = userCredential;
      // set user role as customer (non admin)
      setCustomerRole(signupMail, customerAccountSelected).then(() => {
        // Sentry.captureMessage(`user created ${user.uid} @ ${customerAccountSelected}`);
        assignUserCreatedAlertObject({ signupMail, customerAccountSelected, result: 'success' });
        setUserCreatedAlertStatus(true);
      });
      // }, (error) => {
      //   console.log(error);
      //   assignUserCreatedAlertObject({ signupMail, customerAccountSelected,
      //  result: error.code });
      //   setUserCreatedAlertStatus(true);
      // });
      setAddButtonState(false);
    }
    if (addButtonState) {
      try {
        setUserCreatedAlertStatus(false);
        signUp(authSession);
        console.log('signed up.');
      } catch (error) {
        Sentry.captureMessage('Error in signUpTest (NewPassword.jsx).');
        Sentry.captureException(error);
      }
    }
  }, [addButtonState]);

  return (
    <>
      <Col xs="12" md="5" lg="3" xxl="2" className="mb-1 d-flex justify-content-center align-items-end">
        {/* <Box sx={{ display: 'flex', alignItems: 'flex-end' }}> */}
        <AlternateEmailIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
        <TextField
          fullWidth
          id="input-with-sx"
          label="Mail myKomeo"
          variant="standard"
          onChange={((event) => setSignupMail(event.target.value))}
        />
        {/* </Box> */}
      </Col>
      <Col lg="3" md="5" xxl="2" className="mb-1">
        <FormControl variant="standard" fullWidth>
          <InputLabel id="komeo-account-select-label">Client Komeo</InputLabel>
          <Select
            fullWidth
            labelId="komeo-account-select-label"
            id="komeo-account-select"
            value={customerAccountSelected}
            onChange={(e) => {
              setCustomerAccountSelected(e.target.value);
            }}
          >
            {clientList.map((client) => (
              <MenuItem key={client.id} value={client.name}>{client.name}</MenuItem>
            ))}
            <MenuItem value="Komeadmin">Compte Admin</MenuItem>
          </Select>
        </FormControl>
      </Col>
      <Col xs="12" md="2" lg="2" xl="2" xxl="1">
        <Button
          fullWidth
          color="primary"
          variant="contained"
          endIcon={<PersonAddAlt1Icon />}
          onClick={() => setAddButtonState(true)}
        >
          Ajouter
        </Button>
      </Col>
    </>
  );
}

AddUserForm.propTypes = {
  clientList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  password: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  authSession: PropTypes.object.isRequired,
  setUserCreatedAlertStatus: PropTypes.func.isRequired,
  assignUserCreatedAlertObject: PropTypes.func.isRequired,
};
export default AddUserForm;
