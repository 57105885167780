/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';

import { Card, CardBody, Badge } from 'reactstrap';
import Avatar from '../Avatar';
import refAlexandre from '../../assets/images/equipe/Alexandre.jpg';
import refAntony from '../../assets/images/equipe/Antony.jpg';
import refFlorian from '../../assets/images/equipe/Florian.jpg';
import refHugo from '../../assets/images/equipe/Hugo.jpg';
import refKyllian from '../../assets/images/equipe/Kyllian.jpg';
import refLouca from '../../assets/images/equipe/Louca.jpg';
import refLucas from '../../assets/images/equipe/Lucas.jpg';
import refSarah from '../../assets/images/equipe/Sarah.jpg';

// import '../style/components/ReferentCard.css';
import './style.css';

function ReferentCard(props) {
  const referents = [
    {
      name: 'Alexandre',
      image: refAlexandre,
    },
    {
      name: 'Antony',
      image: refAntony,
    },
    {
      name: 'Florian',
      image: refFlorian,
    },
    {
      name: 'Hugo',
      image: refHugo,
    },
    {
      name: 'Kyllian',
      image: refKyllian,
    },
    {
      name: 'Louca',
      image: refLouca,
    },
    {
      name: 'Lucas',
      image: refLucas,
    },
    {
      name: 'Sarah',
      image: refSarah,
    },
  ];

  const getReferentPicture = (referent) => referent.name === props.referent.firstName;

  return (
    <Card className="card-profile referent-card">
      <CardBody className="d-flex referent-card-body">
        <div className="profile-image-wrapper referent-image-wrapper align-items-center flex-column">
          <div id="referent-profile-image" className="profile-image" style={{ position: 'static' }}>
            <Avatar
              imgClassName="referent-card-img"
              img={referents.find(getReferentPicture).image}
            />
          </div>
        </div>
        <div className="d-flex flex-column referent-info-container">
          <h4 className="font-weight-bolder">
            {props.referent.firstName}
            {' '}
            {props.referent.lastName}
          </h4>
          <div className="badge-referent-container">
            <Badge id="referent-badge" className="profile-badge" color="dark">
              Référent
            </Badge>
          </div>
          <a href={`tel:${props.referent.phone}`}>
            <h5>{props.referent.phone}</h5>
          </a>
          <a href={`mailto:${props.referent.mail}`}>
            <h5>{props.referent.mail}</h5>
          </a>
          <h5>
            Agence de Petit Quevilly,
            <br />
            {' '}
            72 rue de la République
          </h5>
        </div>
      </CardBody>
    </Card>
  );
}
export default ReferentCard;
