/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/prefer-default-export */
import { React } from 'react';
import { Badge } from 'reactstrap';

import { sortContentsFunction } from '../../utils/dataTable';

export const clientContentsColumns = [
  {
    name: 'Statut',
    selector: 'status',
    // selector: row => row.status,
    sortable: true,
    sortFunction: sortContentsFunction,
    minWidth: '120px',
    maxWidth: '117px',
    cell: (row) => (
      <Badge color={row.themeColor} className="badge-glow" pill>
        {row.status}
      </Badge>
    ),
  },
  {
    name: 'Titre',
    selector: 'name',
    // selector: row => row.name,
    sortable: true,
    minWidth: '220px',
    maxWidth: '400px',
    wrap: true,
  },
  {
    name: 'Type',
    selector: 'type',
    // selector: row => row.type,
    sortable: true,
    minWidth: '130px',
    wrap: true,
  },
  {
    name: 'Quant.',
    selector: 'quantity',
    // selector: row => row.quantity,
    sortable: true,
    minWidth: '120px',
    maxWidth: '150px',
  },
  {
    name: 'Points',
    selector: 'cost',
    // selector: row => Math.abs(row.cost),
    sortable: true,
    minWidth: '110px',
    maxWidth: '117px',
  },
  {
    name: 'Description',
    selector: 'details',
    sortable: false,
    minWidth: '290px',
    wrap: true,
  },
  // {
  //     sortable: true,
  //     name: 'Livraison',
  //     minWidth: '250px',
  //     selector: row => row.content_rendering_date
  // },
  // {
  //     sortable: true,
  //     name: 'Shooting',
  //     minWidth: '250px',
  //     selector: row => row.content_shooting_date
  // }
];
