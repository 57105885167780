/* eslint-disable no-unused-vars */
import * as Sentry from '@sentry/browser';
import { React, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  useQuery,
} from '@tanstack/react-query';
import { getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';

import { Col, Row } from 'reactstrap';

// import '../../style/components/UsersManagement.css';
// import '../../../style/components/UsersManagement.css';
import './style.css';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Alert, AlertTitle, CircularProgress } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { API_LINK, fetchAllFirebaseUsers } from '../../../../utils/api/firebase-functions';
import getCustomersList from '../../../../utils/api/flask';
import clearSession from '../../../../utils/clearSession';
import '../../../../utils/api/firebase-auth/onAuthStateChangeWatcher';
import Header from '../../../Header';
import EnhancedTable from '../../../UsersTable';
import AddUserForm from '../../../AddUserForm';
import FeedBackAlert from '../../../FeedBackAlert';

function ValueComponent({ value }) {
  return (
    <div className="d-flex justify-content-center align-items-center mt-1">
      <div className="copy-value">{value}</div>
      <ContentCopyIcon
        className="copy-icon"
        onClick={() => { navigator.clipboard.writeText(value); }}
      />
    </div>
  );
}

ValueComponent.propTypes = {
  value: PropTypes.string.isRequired,
};

function UserCreatedAlert({ signupMail, customerAccountSelected, result }) {
  const hasBeenCreatedMessage = ['Un compte myKomeo a bien été créé pour ', ' à l\'adresse suivante : ', '.'];
  const emailAlreadyExistMessage = ['Le compte myKomeo n\'a pas été créé pour ', ' car l\'adresse ', ' existe déjà.'];
  const defaultErrorMessage = ['Le compte myKomeo n\'a pas été créé pour ', ' à l\'adresse ', ', une erreur est survenue.'];
  let severity = '';
  let message = '';
  if (result === 'success') {
    severity = 'success';
    message = hasBeenCreatedMessage;
  } else {
    severity = 'error';
    message = defaultErrorMessage;
    if (result === 'auth/email-already-in-use') {
      message = emailAlreadyExistMessage;
    }
  }
  return (
    <Alert severity={severity} className="m-2">
      <AlertTitle>
        {message[0]}
        <strong>{customerAccountSelected}</strong>
        {message[1]}
        <strong>{signupMail}</strong>
        {message[2]}
      </AlertTitle>
    </Alert>
  );
}

UserCreatedAlert.propTypes = {
  signupMail: PropTypes.string,
  customerAccountSelected: PropTypes.string.isRequired,
  result: PropTypes.string,
};

UserCreatedAlert.defaultProps = {
  signupMail: '',
  result: 'success',
};

export default function UsersManagement() {
  const [authState, setAuthState] = useState(undefined);
  const [authSession, setAuthSession] = useState(undefined);

  const [sessionChecked, setSessionChecked] = useState(false);
  const [password, setPassword] = useState('');
  const [clientList, setClientList] = useState([]);

  // alert
  const [userCreatedAlertStatus, setUserCreatedAlertStatus] = useState(false);
  const [alertObject, setAlertObject] = useState({});

  // check if loged as admin
  const localStorageSession = JSON.parse(window.localStorage.getItem('KomeoSession'));

  // conf firebase
  const [firebaseConf, setFirebaseConf] = useState({});
  const [firebaseConfReqState, setFirebaseConfReqState] = useState(false);

  // get client's names
  useEffect(() => {
    const getCustomers = async () => {
      setClientList(await getCustomersList(localStorageSession));
    };
    try {
      if (localStorageSession?.customerAccount) getCustomers();
    } catch (error) {
      Sentry.captureMessage('Error in getCustomersList (NewPassword.jsx).');
      Sentry.captureException(error);
    }
  }, []);

  const {
    isLoading, isError, data, error,
  } = useQuery({
    queryKey: ['getallusers'],
    queryFn: fetchAllFirebaseUsers,
  });

  useEffect(() => {
    async function returnFirebaseApp() {
      const url = 'https://getfirebaseconfigv2-q22uw2353q-uc.a.run.app';
      const fireConfReq = await fetch(
        url,
        { method: 'GET', mode: 'cors' },
      );
      if (fireConfReq.status === 200) {
        const jsonFireConf = await fireConfReq.json();
        setFirebaseConf(jsonFireConf);
        setFirebaseConfReqState(true);
        return true;
      }
      return false;
    }
    try {
      // const firebaseConf = returnFirebaseApp();
      returnFirebaseApp();
    } catch (fetchError) {
      Sentry.captureMessage('Error in firebase initializing and auths\' useEffect (NewPassword.jsx)');
      Sentry.captureException(fetchError);
    }
  }, []);

  useEffect(() => {
    if (firebaseConfReqState) {
      try {
        const firebaseConfig = {
          apiKey: firebaseConf.apiKey,
          authDomain: firebaseConf.authDomain,
          projectId: firebaseConf.projectId,
          storageBucket: firebaseConf.storageBucket,
          messagingSenderId: firebaseConf.messagingSenderId,
          appId: firebaseConf.appId,
          measurementId: firebaseConf.measurementId,
        };
        const app = initializeApp(firebaseConfig);
        const auth = getAuth(app);
        setAuthState(true);
        setAuthSession(auth);
      } catch (fetchError) {
        Sentry.captureMessage('Error in firebase initializing and auths\' useEffect (NewPassword.jsx)');
        Sentry.captureException(fetchError);
      }
    }
  }, [firebaseConfReqState]);

  useEffect(() => {
    const fetchPasswordData = async () => {
      const graphService = await API_LINK();
      const getRequest = await fetch(
        `${graphService}/one-password`,
        {
          method: 'GET',
          headers: { token: localStorageSession.token },
          mode: 'cors',
        },
      );
      const jsonPasswordData = await getRequest.json();
      if (jsonPasswordData.status === 200) {
        setPassword(jsonPasswordData.data.password);
      } else clearSession();
    };
    if (localStorageSession?.customerAccount === 'Komeadmin') {
      try {
        fetchPasswordData();
      } catch (fetchError) {
        Sentry.captureMessage('Error in fetchPasswordData (NewPassword.jsx).');
        Sentry.captureException(fetchError);
      }
    }
  }, []);

  useEffect(() => {
    if (localStorageSession?.customerAccount === 'Komeadmin') setSessionChecked(true);
    else clearSession();
  }, []);

  if (!sessionChecked || !authState) {
    return <p>Chargement ..</p>;
  }

  return (
    <div className="height-60-per d-flex flex-column">
      <Row className="justify-content-center">
        <Header admin />
      </Row>
      <div>
        <Row className="justify-content-center align-items-center mx-5">
          <h2 className="text-center">Nouveau compte myKomeo</h2>
        </Row>
        {userCreatedAlertStatus && (
          <Row>
            <Col>
              <UserCreatedAlert
                signupMail={alertObject.signupMail}
                customerAccountSelected={alertObject.customerAccountSelected}
                result={alertObject.result}
              />
              {/* <FeedBackAlert
                type="success"
                sentence={[
                  { value: 'Un compte myKomeo a bien été créé pour' },
                  { value: alertObject.customerAccountSelected, bold: true },
                  { value: 'à l\'adresse suivante :' },
                  { value: alertObject.signupMail, bold: true },
                ]}
              /> */}
            </Col>
          </Row>
        )}
        <Row className="justify-content-center align-items-center mx-5">
          {clientList.length > 0 ? (
            <AddUserForm
              clientList={clientList}
              password={password}
              authSession={authSession}
              setUserCreatedAlertStatus={(state) => setUserCreatedAlertStatus(state)}
              assignUserCreatedAlertObject={(newAlertObject) => setAlertObject(newAlertObject)}
            />
          ) : (
            <CircularProgress />
          )}
        </Row>
        <Row className="justify-content-center align-items-center mx-5 mt-2">
          <h2 className="text-center">Utilisateurs</h2>
        </Row>
        <Row className="justify-content-center align-items-center mx-5">
          {isLoading && (
            <CircularProgress />
          )}
          {isError && (
            <FeedBackAlert
              type="error"
              sentence={[
                { value: 'Impossible de charger les utilisateurs', bold: true },
              ]}
            />
          )}
          {!isLoading && !isError && (
          <EnhancedTable
            clientList={clientList}
            users={data}
          />
          )}
        </Row>
      </div>
    </div>
  );
}
