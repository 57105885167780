/* eslint-disable no-plusplus */
/* eslint-disable no-empty */
/* eslint-disable no-param-reassign */
// [array, int -> array]
// swap position of an array element with his next array value
// swap [a, b, c, d] -> [a, b, d, c] is swap([a, b, c, d], 2)

const swap = (arr, elementIdToReverse) => {
  const returnedArray = arr;
  const elementToReverse = returnedArray[elementIdToReverse];
  returnedArray[elementIdToReverse] = returnedArray[elementIdToReverse + 1];
  returnedArray[elementIdToReverse + 1] = elementToReverse;
  return returnedArray;
};

const bubbleSort = (arr) => {
  // last index value
  const stopIndex = arr.length - 1;
  let returnedArray = arr;

  for (let iterationIndex = 0; iterationIndex < returnedArray.length; iterationIndex += 1) {
    for (let arrayElementIndex = 0; arrayElementIndex < stopIndex; arrayElementIndex += 1) {
      const element = returnedArray[arrayElementIndex];
      const nextElement = returnedArray[arrayElementIndex + 1];

      if (element.statusOrderIndex > nextElement.statusOrderIndex
        || Math.round(element.createdAt) < Math.round(nextElement.createdAt)) {
        returnedArray = swap(returnedArray, arrayElementIndex);
      }
    }
  }

  return arr;
};

const sortContentsFunction = (first, second) => {
  if (first.statusOrderIndex < second.statusOrderIndex) {
    return -1;
  } if (first.statusOrderIndex > second.statusOrderIndex) {
    return 1;
  }
  if (first.createdAt > second.createdAt) {
    return -1;
  } if (Math.round(first.createdAt) < Math.round(second.createdAt)) {
    return 1;
  } return 0;
};

export { sortContentsFunction, bubbleSort };

// function swap(arr, first_Index, second_Index){
//     var temp = arr[first_Index];
//     arr[first_Index] = arr[second_Index];
//     arr[second_Index] = temp;
// }

// function bubble_Sort(arr){
//
//     var len = arr.length,
//         i, j, stop;
//
//     for (i=0; i < len; i++){
//         for (j=0, stop=len-i; j < stop; j++){
//             if (arr[j] > arr[j+1]){
//                 swap(arr, j, j+1);
//             }
//         }
//     }
//
//     return arr;
// }
// console.log(bubble_Sort([3, 0, 2, 5, -1, 4, 1]));
