/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { memo, useEffect, useState } from 'react';
import {
  Button, Card, Col, Label, Row,
} from 'reactstrap';
import DataTable from 'react-data-table-component';

import { useDispatch } from 'react-redux';

import ReactPaginate from 'react-paginate';
import { ChevronDown, Eye, EyeOff } from 'react-feather';
import { clientContentsColumns } from './data';

import '../../style/components/CustomerContents.css';

// class CustomerContents extends React.Component {
function CustomerContents(props) {
  // ** Store Vars
  // const dispatch = useDispatch();
  // const store = useSelector(state => state.customerContents)

  // ** States
  const [currentPage, setCurrentPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(7)
  const [rowsPerPage] = useState(7);
  // const [searchValue, setSearchValue] = useState('')
  // const [searchValue] = useState('')
  const [contentToDisplay, setContentToDisplay] = useState(props.contents.list);
  const [filterActive, setFilterActive] = useState(false);
  const [actualSubContents, setActualSubContents] = useState([]);
  const [isNewCustomer, setIsNewCustomer] = useState(true);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    //    feed an array without contents of olders subscriptions
    const _actualSubContents = props.contents.list.reduce((total, item) => {
      if (item.isActualSub === true) {
        return [
          ...total,
          item,
        ];
      }
      return [
        ...total,
      ];
    }, []);
    // if the customer doesn't have past sub contents,
    // don't update the array and keep isNewCustomer at true
    if (_actualSubContents.length !== props.contents.units) {
      setActualSubContents(_actualSubContents);
      setIsNewCustomer(false);
    }
  }, []);

  const handleSearchFilter = (e) => {
    const { value } = e.target;
    let updatedData = [];
    setSearchValue(value);

    if (value.length) {
      updatedData = contentToDisplay.filter((item) => {
        const startsWith = item.status.toLowerCase().startsWith(value.toLowerCase())
                    || item.name.toLowerCase().startsWith(value.toLowerCase())
                    || item.type.toLowerCase().startsWith(value.toLowerCase())
                    // item.quantity.toLowerCase().startsWith(value.toLowerCase()) ||
                    // item.cost.toLowerCase().startsWith(value.toLowerCase()) ||
                    || item.details.toLowerCase().startsWith(value.toLowerCase());

        const includes = item.status.toLowerCase().includes(value.toLowerCase())
                    || item.name.toLowerCase().includes(value.toLowerCase())
                    || item.type.toLowerCase().includes(value.toLowerCase())
                    // item.quantity.toLowerCase().includes(value.toLowerCase()) ||
                    // item.cost.toLowerCase().includes(value.toLowerCase()) ||
                    || item.details.toLowerCase().includes(value.toLowerCase());

        if (startsWith) {
          return startsWith;
        } if (!startsWith && includes) {
          return includes;
        } return null;
      });
      setContentToDisplay(updatedData);
      setSearchValue(value);
    }
  };

  const handlePagination = (page) => {
    setCurrentPage(page.selected);
  };

  const dataToRender = () => contentToDisplay;
  // return props.contents.list

  const handleFiltering = () => {
    if (!filterActive) {
      setContentToDisplay(actualSubContents);
    } else setContentToDisplay(props.contents.list);
    setFilterActive(!filterActive);
  };

  // ** Custom Pagination
  function CustomPagination() {
    return (
      <ReactPaginate
        previousLabel=""
        nextLabel=""
        breakLabel="..."
        pageCount={Math.ceil(contentToDisplay.length / rowsPerPage) || 1}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        forcePage={currentPage !== 0 ? currentPage : 0}
        onPageChange={(page) => handlePagination(page)}
        activeClassName="active"
        pageClassName="page-item"
        breakClassName="page-item"
        nextLinkClassName="page-link"
        pageLinkClassName="page-link"
        breakLinkClassName="page-link"
        previousLinkClassName="page-link"
        nextClassName="page-item next-item"
        previousClassName="page-item prev-item"
        containerClassName="pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1 mt-1"
      />
    );
  }

  return (
  // <>
    <Card>
      <Row className="justify-content-end mx-0">
        {isNewCustomer === false ? (
          <Col className="d-flex align-items-center justify-content-end mt-1" md="6" sm="12">
            <Label className="me-1" for="search-input">
              Filtrer
              {' '}
              {isNewCustomer}
            </Label>
            <Button className="ms-2" color="primary" onClick={handleFiltering}>
              {filterActive ? (<EyeOff size={15} />) : (<Eye size={15} />)}
              <span className="align-middle ms-50">Année(s) passée(s)</span>
            </Button>
          </Col>
        ) : ('')}
        {/* <Col className='d-flex align-items-center justify-content-end mt-1' md='6' sm='12'> */}
        {/*    <Label className='me-1' for='search-input'> */}
        {/*        Search */}
        {/*    </Label> */}
        {/*    <Input */}
        {/*        className='dataTable-filter mb-50' */}
        {/*        type='text' */}
        {/*        bsSize='sm' */}
        {/*        id='search-input' */}
        {/*        value={searchValue} */}
        {/*        onChange={handleSearchFilter} */}
        {/*    /> */}
        {/* </Col> */}
      </Row>
      <div className="react-dataTable">
        <DataTable
          noHeader
          pagination
          noDataComponent="Aucun contenu à afficher ici pour l'instant.."
          columns={clientContentsColumns}
          paginationPerPage={rowsPerPage}
          className="react-dataTable"
          sortIcon={<ChevronDown size={10} />}
          paginationDefaultPage={currentPage + 1}
          paginationComponent={CustomPagination}
                        // data={props.contents.list}
          data={dataToRender()}
        />
      </div>
    </Card>
  );
  // }
}
export default memo(CustomerContents);
