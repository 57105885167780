import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { onAuthStateChanged } from 'firebase/auth';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { Row, Col } from 'reactstrap';
import { updateFirebaseUsers } from '../../../utils/api/firebase-functions';
import { checkUserToken, getUserEmail } from '../../../utils/api/firebase-auth';

import FeedBackAlert from '../../FeedBackAlert';

import './style.css';

export default function DialogPasswordDeactivation({ open, onClose, auth }) {
  const [mail, setMail] = useState('');

  const [isMatched, setIsMatched] = useState(false);
  const [step, setStep] = useState(1);
  const [hasToVerifyToken, setHasToVerifyToken] = useState(true);
  const session = JSON.parse(localStorage.getItem('KomeoSession'));

  useEffect(() => {
    setIsMatched(false);
    if (session.mail === mail) {
      setIsMatched(true);
    }
  }, [mail]);

  const handleOnClick = async () => {
    const isSessionExpired = await checkUserToken();
    if (isSessionExpired) window.location.href = '/reconnect';
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const { uid, email } = user;
        updateFirebaseUsers('resetAuth', [{ uid, email }])
          .then((response) => {
            if (response.status === 201) {
              setHasToVerifyToken(true);
              setStep(2);
              setTimeout(() => {
                window.location.href = '/profile/me';
              }, 4000);
            }
          });
      }
    });
  };

  const clearAndClose = () => {
    setMail('');
    onClose();
  };

  useEffect(() => {
    async function setUpAndClearSession() {
      const isSessionExpired = await checkUserToken();
      setHasToVerifyToken(false);
      if (isSessionExpired) {
        setStep(0);
        const email = await getUserEmail();
        localStorage.setItem('komeoReauthentMail', email);
        window.location.href = '/reconnect';
      } else if (step === 0) {
        setStep(1);
      }
    }
    if (open && step < 2) {
      if (hasToVerifyToken) {
        setUpAndClearSession();
      }
    }
  }, [step, open]);

  return (
    <Dialog
      open={open}
      onClose={clearAndClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {step === 1 && (
        <>
          <DialogTitle id="alert-dialog-title">Suppression de votre mot de passe</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Êtes vous sûr de vouloir passer à une authentification par lien e-mail ?
            </DialogContentText>
            <DialogContentText id="alert-dialog-slide-description">
              Pour confirmer, retapez votre adresse mail dans le champ suivant:
            </DialogContentText>
            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
              <Row>
                <Col xs="12">
                  <Box fullWidth sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
                    <AlternateEmailIcon sx={{ color: isMatched ? 'action.active' : 'red', mr: 1, my: 0.5 }} />
                    <TextField
                      error
                      id="input-with-sx"
                      label={session.mail}
                      autoComplete="off"
                      value={mail}
                      variant="standard"
                      onChange={(event) => setMail(event.target.value)}
                    />
                  </Box>
                </Col>
              </Row>
              <Row className="justify-content-center mt-1">
                <Col xs="12" sm="8" md="8" lg="8" xl="8" xxl="8" className="mb-1">
                  <Button
                    fullWidth
                    id="submitConfirmation"
                    variant="outlined"
                    size="medium"
                    onClick={clearAndClose}
                    color="error"
                  >
                    Annuler
                  </Button>
                </Col>
                <Col xs="12" sm="4" md="4" lg="4" xl="4" xxl="4" className="mb-1">
                  <Button
                    fullWidth
                    id="submitConfirmation"
                    variant="outlined"
                    size="medium"
                    disabled={!isMatched}
                    onClick={handleOnClick}
                    color="success"
                  >
                    Confirmer
                  </Button>
                </Col>
              </Row>
            </FormControl>
          </DialogContent>
        </>
      )}
      {step === 2 && (
        <DialogContent>
          <Row>
            <Col>
              <FeedBackAlert type="success" sentence={[{ value: 'L\'authentification à votre compte se fait désormais par lien mail', bold: true }]} />
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-end">
              <Button
                onClick={clearAndClose}
                size="medium"
              >
                Fermer
              </Button>

            </Col>
          </Row>
        </DialogContent>
      )}
    </Dialog>
  );
}

DialogPasswordDeactivation.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  auth: PropTypes.object.isRequired,
};
