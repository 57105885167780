import * as Sentry from '@sentry/browser';
import { signOut } from 'firebase/auth';

function clearSession(_auth, location = '/') {
  window.localStorage.removeItem('KomeoSession');
  // firebase signOut
  if (_auth) {
    signOut(_auth).then(() => {
      if (location === null) {
        return true;
      }
      window.location.href = location;
      return true;
    }).catch((error) => {
      Sentry.captureMessage('erreur lors de la déconnexion');
      Sentry.captureException(error);
      return false;
    });
  } else { window.location.href = location; return true; }
  return true;
}
export default clearSession;
