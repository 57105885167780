/* eslint-disable no-unused-vars */
import * as Sentry from '@sentry/browser';

import { initializeApp } from 'firebase/app';
import { getAuth, sendSignInLinkToEmail, onAuthStateChanged } from 'firebase/auth';

import { isSessionExpired, patchLSSession } from '../../vanilla-js/auth';

function buildFirebaseConfObject(firebaseConf) {
  return {
    apiKey: firebaseConf.apiKey,
    authDomain: firebaseConf.authDomain,
    projectId: firebaseConf.projectId,
    storageBucket: firebaseConf.storageBucket,
    messagingSenderId: firebaseConf.messagingSenderId,
    appId: firebaseConf.appId,
    measurementId: firebaseConf.measurementId,
  };
}

async function getFirebaseConf() {
  const url = 'https://getfirebaseconfigv2-q22uw2353q-uc.a.run.app';
  const fireConfReq = await fetch(
    url,
    { method: 'GET', mode: 'cors' },
  );
  if (fireConfReq.status === 200) {
    const jsonFireConf = await fireConfReq.json();
    return buildFirebaseConfObject(jsonFireConf);
  }
  return false;
}

export async function initFireApp() {
  try {
    const fireConf = await getFirebaseConf();
    const firebaseApp = initializeApp(fireConf);
    return firebaseApp;
  } catch (error) {
    Sentry.captureMessage('Error in firebase initializing and auths\' useEffect (firebaseApp.js)');
    Sentry.captureException(error);
    return false;
  }
}

// async function getUserTokenResult() {
//   const app = await initFireApp();
//   const auth = getAuth(app);
//   onAuthStateChanged(auth, (user) => {
//     let token = null;
//     if (user) {
//       user.getIdTokenResult(false).then((tokenResult) => {
//         token = tokenResult.token;
//         const { customerAccount } = tokenResult.claims;
//         const session = JSON.parse(localStorage.getItem('KomeoSession'));
//         const sessionRefreshed = {
//           ...session,
//           token,
//           customerAccount,
//         };
//         patchLSSession(sessionRefreshed);
//         return tokenResult;
//       });
//     }
//     return false;
//   });
//   // return tokenResult;
// }

async function getUserTokenResult() {
  const app = await initFireApp();
  const auth = getAuth(app);
  let token = null;
  if (auth.currentUser) {
    auth.currentUser.getIdTokenResult(false).then((tokenResult) => {
      token = tokenResult.token;
      const { customerAccount } = tokenResult.claims;
      const session = JSON.parse(localStorage.getItem('KomeoSession'));
      const sessionRefreshed = {
        ...session,
        token,
        customerAccount,
      };
      patchLSSession(sessionRefreshed);
      return tokenResult;
    });
  }
  return false;
}
async function getCurrentUser() {
  const app = await initFireApp();
  const auth = getAuth(app);
  if (auth.currentUser) {
    return auth.currentUser;
  } return false;
}

export async function sendMailLinkLogin(email = null) {
  let isSentSuccessfull = false;
  let userMail = email;
  try {
    const auth = getAuth();
    if (userMail === null) userMail = auth.currentUser.credentials.email;
    const linkToEmail = await sendSignInLinkToEmail(
      auth,
      userMail,
      { url: `${window.location.origin}/profile/me`, handleCodeInApp: true },
      // { url: 'http://localhost:3000/profile/me', handleCodeInApp: true },
      // { url: 'http://localhost:3000', handleCodeInApp: true },
    );
    console.log(linkToEmail);
    // .then(() => {
    //   localStorage.setItem('emailForSignIn', user.email);
    //   return true;
    // });
    localStorage.setItem('emailForSignIn', userMail);
    isSentSuccessfull = true;
    // if (reqResult) {
    // }
  } catch (error) {
    console.log('sendMailLinkLogin error');
    console.log(error);
  }
  return isSentSuccessfull;
}

export async function getUserCustomerAccount() {
  let customerAccount = null;
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    const tokenResult = await user.getIdTokenResult(false);
    if (tokenResult.claims.customerAccount) {
      customerAccount = tokenResult.claims.customerAccount;
      return customerAccount;
    }
    return null;
  } catch (error) {
    console.log('error in getUserEmail()');
    console.log(error);
  }
  return customerAccount;
}

export async function getUserEmail() {
  let email = null;
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    const tokenResult = await user.getIdTokenResult(false);
    if (tokenResult.claims.email) {
      email = tokenResult.claims.email;
      return email;
    }
    return null;
  } catch (error) {
    console.log('error in getUserEmail()');
    console.log(error);
  }
  return email;
}

export async function getUserAuthMethod() {
  let authMethod = null;
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    const tokenResult = await user.getIdTokenResult(false);
    if (tokenResult.claims.authMethod) {
      authMethod = tokenResult.claims.authMethod;
      return authMethod;
    }
    return null;
  } catch (error) {
    console.log('error in getUserAuthMethod()');
    console.log(error);
  }
  return authMethod;
}

export async function checkUserToken() {
  const tokenResult = await getUserTokenResult();
  return isSessionExpired(tokenResult.authTime);
}

export async function isUserAdmin() {
  // try {
  const currentUser = await getCurrentUser();
  return currentUser.getIdTokenResult(false).then((tokenResult) => tokenResult.claims.customerAccount === 'Komeadmin');
  // } catch (error) {
  //   console.log(error);
  //   return error;
  // }
}
