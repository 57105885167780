export function isSessionExpired(authTime) {
  const authTimestamp = Date.parse(authTime); // Convertir la date en timestamp

  const currentTimestamp = new Date();
  const currTimestampString = currentTimestamp.toLocaleString('fr-FR', { timeZone: 'Europe/Paris' });

  const expirationTimestamp = authTimestamp + 5 * 60 * 1000; // Ajouter 5 minutes en millisecondes
  const expirationDate = new Date(expirationTimestamp);
  const expirationString = expirationDate.toLocaleString('fr-FR', { timeZone: 'Europe/Paris' });

  console.log(expirationString, currTimestampString);
  // const sixMinutesFromNow = currentTimestamp + 58 * 60 * 1000;
  // pour dev le front - très vite avoir l'étape de reauthentification

  if (expirationTimestamp <= currentTimestamp) {
    return true;
  }
  // La date d'expiration est dans le futur (plus de 6 minutes)
  return false;
}

export function patchLSSession(session) {
  localStorage.setItem('KomeoSession', JSON.stringify(session));
}
