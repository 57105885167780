/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import * as Sentry from '@sentry/browser';
import clearSession from '../../../../utils/clearSession';
import '../../../../utils/api/firebase-auth/onAuthStateChangeWatcher';
import { API_LINK } from '../../../../utils/api/firebase-functions';

import './style.css';
import '../../../../style/snippets/menuButton.css';

import Header from '../../../Header';
import Loader from '../../../Loader';

export default function CustomersList() {
  // ReactGA.send({ hitType: "pageview", page: window.location.pathname });

  const [customersData, setcustomersData] = useState(null);
  const [updateState, setUpdateState] = useState(null);
  const localStorageSession = JSON.parse(window.localStorage.getItem('KomeoSession'));

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // todo : trouver un moyen de requêter ça uniquement quand le compte admin est connecté
    const fetchData = async () => {
      if (localStorageSession) {
        const graphService = await API_LINK();
        const resCustomers = await fetch(
          `${graphService}/v2/customers`,
          {
            method: 'GET',
            headers: { token: localStorageSession.token },
            mode: 'cors',
          },
        );
        const jsonCustomers = await resCustomers.json();
        setLoading(false);
        if (jsonCustomers.status === 403) {
          // todo : une alert pour indiquer le retour au login
          clearSession();
        }
        setcustomersData(jsonCustomers);
      } else clearSession();
    };
    try {
      fetchData();
    } catch (error) {
      Sentry.captureMessage('error in fetchData CustomersList.jsx');
      Sentry.captureException(error);
    }
  }, []);

  async function getGraphUpdate() {
    setUpdateState('working');
    const graphServiceLink = await API_LINK();
    const graphReq = await fetch(
      `${graphServiceLink}/v2/excel/generate`,
      {
        method: 'GET',
        mode: 'cors',
      },
    );
    if (graphReq.status === 200) {
      const jsonGraphReq = await graphReq.json();
      if (jsonGraphReq.local_db_creation_state && jsonGraphReq.old_local_db_moving_state) {
        setUpdateState('success');
      } else {
        setUpdateState('fail');
      }
    }
  }

  let updatedPrompt = ' ';
  switch (updateState) {
    case null:
      updatedPrompt = '';
      break;
    case 'success':
      updatedPrompt = '🟢';
      break;
    case 'working':
      updatedPrompt = '🟠';
      break;
    case 'fail':
      updatedPrompt = '🔴';
      break;
    default:
      updatedPrompt = '⚠️';
      break;
  }

  return (
    <div className="full-height d-flex flex-column">
      <Loader loading={loading} />
      <div id="app-content">
        <Header
          admin
          customerName="admin"
        />
        {/* <Row className="full-width d-flex justify-content-center m-0">
          <Col sm="12" xl="2" md="12" xs="12">
            <LogOut />
          </Col>
        </Row> */}
        <Row className="flex-shrink-1 flex-grow-1">
          <Col className="d-flex justify-content-center flex-column">
            <h1 className="text-center">Menu</h1>
            <ul
              id="features-menu"
              className="d-flex flex-column align-items-center align-content-center
                                justify-content-center mt-1"
            >
              <li className="list-unstyled">
                <button type="button" id="refresh-button" className="list-unstyled menu-button cursor-pointer" onClick={getGraphUpdate} onKeyDown={getGraphUpdate}>
                  🔄 Excel
                  {updatedPrompt}
                </button>
              </li>
              <li className="list-unstyled menu-button">
                <Link to="/admin/manage-users">Gérer les comptes clients</Link>
              </li>
            </ul>
            <hr className="solid-divider align-self-center" />
            <ul
              id="clients-menu"
              className="
                                d-flex flex-column align-items-center align-content-center justify-content-center
                            "
            >
              {customersData?.map((client) => (
                <li className="list-unstyled menu-button client-button" key={client.id}>
                  <Link to={`/admin/customer/${client.name}`}>
                    {client.name}
                  </Link>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
        <Outlet />
      </div>
    </div>
  );
}
