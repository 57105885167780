import React, { useEffect, useState } from 'react';
import './style.css';

import { Col, Row } from 'reactstrap';

import Button from '@mui/material/Button';

import { getAuth } from 'firebase/auth';
import { sendMailLinkLogin } from '../../../utils/api/firebase-auth';

import getFirebaseInstance from '../../../firebase';

import BackNavigationButton from '../../BackNavigationButton';
import FeedBackAlert from '../../FeedBackAlert';
import clearSession from '../../../utils/clearSession';

export default function EmailReauthenticate() {
  const email = localStorage.getItem('komeoReauthentMail');
  const sentence = ` Cliquez sur le bouton ci-dessous pour recevoir un courrier électronique. 
  Il vous suffira de cliquer sur le lien de connexion qu'il contient. 
  À tout de suite !`;

  // const [firebaseSingletonState, setFirebaseSingletonState] = useState(false);
  const [buttonMessage, setButtonMessage] = useState('Envoyer le mail');
  const [buttonClickedOnce, setButtonClickedOnce] = useState(false);
  const [sendingMailState, setSendingMailState] = useState(undefined);
  const [isDisabled, setIsDisabled] = useState(true);
  const [failCount, setFailCount] = useState(0);

  useEffect(() => {
    // Récupérez l'instance de Firebase de manière asynchrone
    getFirebaseInstance().then((instance) => {
      if (instance) setIsDisabled(false);
    });
  }, []);

  async function sendLoginMail() {
    try {
      if (!buttonClickedOnce) {
        const auth = getAuth();
        clearSession(auth, null);
        setButtonClickedOnce(true);
      }
      sendMailLinkLogin(email).then((isSentSuccessfull) => {
        setIsDisabled(true);
        if (isSentSuccessfull) {
          setButtonMessage('Courrier envoyé ✔️');
          setSendingMailState('success');
          localStorage.setItem('emailForSignIn', email);
        } else {
          if (failCount > 3) {
            clearSession();
          }
          setSendingMailState('fail');
          setFailCount(failCount + 1);
        }
        setTimeout(() => {
          setButtonMessage('Renvoyer le mail');
          setIsDisabled(false);
        }, 3000);
        setTimeout(() => { setSendingMailState(undefined); }, 8000);
      })
        .catch((error) => {
          console.log('error sending mail');
          console.log(error);
        });
    } catch (error) {
      console.log('error');
      console.log(error);
    }
  }

  return (
    <>
      {sendingMailState === 'success' && (
        <FeedBackAlert type="success" sentence={[{ value: 'Le mail a bien été envoyé', bold: true }]} />
      )}
      {sendingMailState === 'fail' && (
        <FeedBackAlert type="error" sentence={[{ value: 'Le mail n\'as pas pu être envoyé, une erreur est survenue', bold: true }]} />
      )}
      <Row className="align-items-start justify-content-center vh-100">
        <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12" className="mh-10">
          <BackNavigationButton />
        </Col>
        <Col xs="12" sm="11" md="10" lg="8" xl="6" xxl="5">
          <div className="messageContainer">
            <div className="messageBox">
              <div className="message-head">
                Cette action nécessite une reconnexion
              </div>
              <div className="message mt-1">
                {sentence}
              </div>
              <div className="d-flex justify-content-center mt-1">
                <Button variant="contained" onClick={() => { sendLoginMail(); }} disabled={isDisabled}>
                  {buttonMessage}
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
