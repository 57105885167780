import * as Sentry from '@sentry/browser';
import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import ReactGA from 'react-ga4';
import { getApp } from 'firebase/app';
import {
  getFunctions,
  httpsCallable,
  // connectFunctionsEmulator,
} from 'firebase/functions';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
// import ContentTypes from '../../ContentTypes';
import { Button } from '@mui/material';
import ContentTypes from '../../ContentTypes';
import ReferentCard from '../../ReferentCard';
import CustomerContents from '../../CustomerContents/CustomerContents';
import Toolbox from '../../Toolbox';
import PointsBalance from '../../PointsBalance';
import '../../../index.css';
import '../../../style/dataTables/react-dataTable-component.scss';
import './style.css';
import clearSession from '../../../utils/clearSession';
import '../../../utils/api/firebase-auth/onAuthStateChangeWatcher';

import Loader from '../../Loader';

import { bubbleSort } from '../../../utils/dataTable';
import Header from '../../Header';

import getFirebaseInstance from '../../../firebase';

export default function CustomerDashboard() {
  // ReactGA.send({ hitType: "pageview", page: window.location.pathname });

  const [dashboardData, setDashboardData] = useState(null);
  const [pointsBalance, setPointsBalance] = useState(null);

  const [firebaseSingleton, setFirebaseSingleton] = useState(null);
  const [firebaseSingletonState, setFirebaseSingletonState] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [isReadyToFetch, setIsReadyToFetch] = useState(false);
  const [isReadyToRender, setIsReadyToRender] = useState(false);
  const [auth, setAuth] = useState(false);

  const params = useParams();
  let customerId = null;
  if (Object.prototype.hasOwnProperty.call(params, 'customerId')) {
    customerId = params.customerId;
  }

  let isAdmin = false;
  const session = JSON.parse(localStorage.getItem('KomeoSession'));
  let hasSessionAdminProperty = false;
  let hasSessionNameProperty = false;
  if (session !== undefined && session !== null) {
    hasSessionAdminProperty = Object.prototype.hasOwnProperty.call(session, 'admin');
    hasSessionNameProperty = Object.prototype.hasOwnProperty.call(session, 'customerAccount');
  }
  if (hasSessionNameProperty && !hasSessionAdminProperty) {
    isAdmin = session.customerAccount === 'Komeadmin';
  } else {
    alert('Authentification invalide, vous allez être redirigé.');
    clearSession();
  }

  useEffect(() => {
    // Récupérez l'instance de Firebase de manière asynchrone
    getFirebaseInstance().then((instance) => {
      setFirebaseSingleton(instance);
      setFirebaseSingletonState(true);
    });
  }, []);

  useEffect(() => {
    if (firebaseSingletonState && !isReadyToFetch) {
      // const { app } = firebaseSingleton;
      const localAuth = getAuth();
      setAuth(localAuth);
      onAuthStateChanged(localAuth, (user) => {
      // onAuthStateChanged(firebaseSingleton.auth, (user) => {
        if (user) {
          user
            .getIdToken(false)
            .then((token) => {
              setUserToken(token);
              setIsReadyToFetch(true);
            })
            .catch((error) => {
              Sentry.captureMessage(
                "erreur lors de l'initialisation de tokenId",
              );
              Sentry.captureException(error);
            });
        } else clearSession();
      });
    }
  }, [firebaseSingleton]);

  const defineOrderAndThemeColor = (content) => {
    const { themeColor, statusOrderIndex, ...returnedContent } = content;
    switch (content.status) {
      case 'en cours':
        returnedContent.themeColor = 'warning';
        returnedContent.statusOrderIndex = 1;
        break;
      case 'à valider':
        returnedContent.themeColor = 'danger';
        returnedContent.statusOrderIndex = 0;
        break;
      case 'réalisé':
        returnedContent.themeColor = 'success';
        returnedContent.statusOrderIndex = 2;
        break;
      case 'publié':
        returnedContent.themeColor = 'info';
        returnedContent.statusOrderIndex = 3;
        break;
      case 'annulé':
        returnedContent.themeColor = 'dark';
        returnedContent.statusOrderIndex = 4;
        break;
      default:
        returnedContent.themeColor = 'dark';
        returnedContent.statusOrderIndex = 5;
        break;
    }
    return returnedContent;
  };

  // GA page view
  useEffect(() => {
    ReactGA.event('screen_view', {
      app_name: 'Espace Komeo',
      screen_name: 'Dashboard',
    });
  });

  const handleDataReceived = (userBasicData, userComputedSubData) => {
    // extract user object, his contents list and format them
    const user = userBasicData.customer;
    const userContentList = user.contents.list;

    // formatting
    const userContentListOrderedAndColored = userContentList.map(
      (content) => defineOrderAndThemeColor(content),
    );
    const userContentSorted = bubbleSort(
      userContentListOrderedAndColored,
    );

    // reassign the formatted contents list in a new customer object
    const { contents, ...formatedUser } = user;
    formatedUser.contents = {
      units: userContentSorted.length,
      list: userContentSorted,
    };

    const customerDashboardData = {
      app: userBasicData.app,
      customer: formatedUser,
    };

    setDashboardData(customerDashboardData);
    setPointsBalance(userComputedSubData);
    setIsReadyToRender(true);
  };

  useEffect(() => {
    const fetchFirebaseFunctionsDashboardData = async () => {
      const functions = getFunctions(getApp());
      // specify the komeoId to fetch data if necessary (admin case)
      // const payload = { token: userToken };
      // if (customerId !== null) { payload.customerId = customerId; }
      const getDashboardData = httpsCallable(functions, 'getdashboarddata');
      getDashboardData({ token: userToken, customerId })
        .then((result) => {
          handleDataReceived(result.data.data.userBasicData, result.data.data.userComputedSubData);
        })
        .catch((error) => {
          Sentry.captureMessage(
            `error ${error.details} : calling getdashboarddata firebase function.`,
          );
          Sentry.captureException(error);
          if (Number.isInteger(error.details)) {
            localStorage.setItem('KomeoServices', JSON.stringify({ status: error.details }));
          }
          switch (error.details) {
            case 503:
              alert('Notre service rencontre actuellement un problème,'
                  + 'veuillez réessayer plus tard.');
              break;
            case 500:
              alert('Une erreur est survenue lors du traitement de votre demande,'
                + 'si le problème persiste veuillez nous contacter');
              break;
            case 403:
              alert('Vous n\'avez pas la permission d\'accéder à cette ressource,'
                  + 'veuillez vous reconnecter.');
              break;
            case 404:
              alert('Impossible d\'accéder à la ressource demandée, vous allez être redirigé.');
              break;
            case 401:
              alert('Le service actuellement indisponible, notre équipe technique a été notifiée de la situation.');
              break;
            default:
              alert('Une erreur inconnue est survenue, si le problème persiste veuillez nous contacter.');
              break;
          }
          clearSession();
        });
    };
    if (isReadyToFetch) {
      fetchFirebaseFunctionsDashboardData();
    }
  }, [isReadyToFetch]);

  // GA page view
  useEffect(() => {
    ReactGA.event('screen_view', {
      app_name: 'Espace Komeo',
      screen_name: 'Dashboard',
    });
  });

  let noPointsDataSentence = '';
  if (pointsBalance === null && dashboardData) {
    if (dashboardData?.customer.isSub_in_progress) {
      noPointsDataSentence = 'Calcul de votre solde de points..';
    } else {
      noPointsDataSentence = 'Pas de forfait en cours..';
    }
  }

  return (
    <main>
      <Loader loading={!isReadyToRender} />
      <div id="app-content">
        {dashboardData !== null && (
          <div
            id="dashboard-client"
            className="d-flex flex-column align-items-center"
          >
            <Header
              admin={isAdmin}
              customerName={dashboardData.customer.customerAccount}
              auth={auth}
              // auth={firebaseSingleton.auth}
            />
            <Row className="full-width full-width-marge match-height">
              <Col sm="12" xl="12" md="12">
                <h1 className="font-weight-bolder text-center">
                  Bonjour
                  {' '}
                  {dashboardData.customer.customerAccount}
                </h1>
                {dashboardData.customer.referent.sentence !== 0 && (
                  <div id="referent-sentence" className="text-center">
                    {dashboardData.customer.referent.sentence}
                  </div>
                )}
              </Col>
            </Row>
            {/* Points */}
            <Row className="full-width full-width-marge match-height justify-content-center">
              {pointsBalance !== null ? (
                <Col sm="12" md="10" lg="4" xl="4">
                  <div className="d-flex flex-row justify-content-evenly points">
                    <PointsBalance balanceData={pointsBalance} />
                  </div>
                </Col>
              ) : (
                <>
                  <Row>
                    <Col className="justify-content-center">
                      <div className="d-flex flex-row justify-content-evenly points">
                        <p>{noPointsDataSentence}</p>
                      </div>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col className="d-flex justify-content-center">
                      <Button
                        variant="contained"
                        href={`mailto:hugo@komeocreation.fr?subject=Nouveau Forfait Komeo&body=Bonjour Komeo, je souhaite démarrer un nouveau forfait avec vous.  ${dashboardData.customer.customerAccount}.`}
                      >
                        Démarrer mon forfait
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
              <Col sm="12" md="10" lg="8" xl="8">
                <div className="d-flex flex-row justify-content-evenly points">
                  <ContentTypes
                    contentTypes={dashboardData.app.contentTypes.list}
                  />
                </div>
              </Col>
            </Row>
            {/* Infos */}
            <Row className="full-width full-width-marge match-height">
              <Col sm="12" xl="12" md="12">
                <h2 className="text-center">Infos</h2>
              </Col>
            </Row>
            <Row className="full-width full-width-marge justify-content-center">
              <Col sm="12" md="9" lg="6" xl="6">
                <ReferentCard referent={dashboardData.customer.referent} />
              </Col>
              <Col md="9" lg="6" xl="6">
                <Toolbox
                  clientName={dashboardData.customer.customerName}
                  creativeDirectory={dashboardData.customer.creative_directory}
                  invoicesDirectory={dashboardData.customer.invoices_directory}
                  referent={dashboardData.customer.referent}
                />
              </Col>
            </Row>
            {/* Creations */}
            <Row className="full-width full-width-marge match-height">
              <Col sm="12" xl="12" md="12">
                <h2 className="text-center">Création</h2>
              </Col>
            </Row>
            {/* <Row className='match-height'> */}
            <Row className="full-width full-width-marge">
              {/* <Col xl='12' md='12' sm='12'> */}
              <Col sm="12">
                <CustomerContents contents={dashboardData.customer.contents} />
              </Col>
            </Row>
            <Row className="full-width full-width-marge">
              <Col className="full-width">
                <div className="text-center mb-1 cursor-pointer">
                  &copy; Komeo 2023 -
                  {' '}
                  <a
                    className="text-decoration-underline"
                    href={`mailto:mathieu@komeocreation.fr,hugo@komeocreation.fr,antony@komeocreation.fr?subject=Bug Report de la part de ${dashboardData.customer.customerName}&body=Bonjour l'équipe,`}
                  >
                    Déclarer un bug
                  </a>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </main>
  );
}
