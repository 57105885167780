/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import {
  Card, CardHeader, CardTitle, CardBody,
} from 'reactstrap';
import CardImage from '../CardImage';

import '../../style/components/ContentTypes.css';

class ContentTypes extends React.Component {
  constructor() {
    super();
    this.state = { types: [] };
  }

  componentDidMount() {
    this.filterContentTypes();
  }

  filterContentTypes() {
    const filteredTypes = this.props.contentTypes.filter((type) => type.description !== '');
    this.setState({
      types: filteredTypes,
    });
  }

  renderContentTypes() {
    return this.state.types.map((item) => (
      <div key={item.id} className="transaction-item tc-transaction-item contentType-item">
        <div className="d-flex content-row">
          <CardImage imgType={item.name.toString()} />
          {/* <h6 */}
          {/*    className= */}
          {/*        'transaction-title font-weight-bolder contentType-heading */}
          {/*        mobile-contentType-name align-self-center' */}
          {/* > */}
          {/*    {item.name} */}
          {/* </h6> */}
          <div className="d-flex flex-column justify-content-center contentType-naming">
            <h6 className="transaction-title font-weight-bolder contentType-heading">{item.name}</h6>
            <small className="contentType-description">{item.description}</small>
          </div>
        </div>
        <div className="fw-bolder text-black contentType-cost align-self-center">{item.cost}</div>
      </div>
    ));
  }

  render() {
    return (
      <Card className="card-transaction" style={{ flex: 3 }}>
        <CardHeader className="justify-content-center">
          <CardTitle tag="h3">Utiliser mes points</CardTitle>
        </CardHeader>
        <CardBody className="tc-card-body justify-content-center">
          {/* todo : fix type unknown is not assignable */}
          {this.renderContentTypes()}
        </CardBody>
      </Card>
    );
  }
}
export default ContentTypes;
