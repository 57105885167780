import { API_LINK } from '../firebase-functions';
import clearSession from '../../clearSession';

export default async function getCustomersList(localStorageSession) {
  const graphService = await API_LINK();
  const resCustomers = await fetch(
    `${graphService}/v2/customers`,
    {
      method: 'GET',
      headers: { token: localStorageSession.token },
      mode: 'cors',
    },
  );
  const jsonCustomers = await resCustomers.json();
  if (jsonCustomers.status === 403) {
    // todo : une alert pour indiquer le retour au login
    clearSession();
  }
  // window.localStorage.setItem('clientsList', jsonCustomers);
  return jsonCustomers;
}
