import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { patchLSSession } from '../../vanilla-js/auth';
import { initFireApp } from './index';

initFireApp().then((app) => {
  try {
    const auth = getAuth(app);
    onAuthStateChanged(auth, (user) => {
      if (!user) { signOut(); }
      user.getIdTokenResult(true).then((tokenResult) => {
        const currentLocation = window.location.pathname;
        if (tokenResult.claims.customerAccount !== 'Komeadmin' && currentLocation.includes('admin')) {
          const session = JSON.parse(localStorage.getItem('KomeoSession'));
          const updatedSession = {
            ...session,
            customerAccount: tokenResult.claims.customerAccount,
          };
          patchLSSession(updatedSession);
          window.location.href = '/';
        }
      });
    });
  } catch (error) {
    console.log(error);
  }
});
