/* eslint-disable no-unused-vars */
import { React } from 'react';
import { Routes, Route } from 'react-router-dom';
import CustomersList from './components/routes/admin/CustomersList';
import UsersManagement from './components/routes/admin/UsersManagement';
import Login from './components/routes/Login';
import CustomerDashboard from './components/routes/CustomerDashboard';
import Profile from './components/routes/Profile';
import EmailReauthenticate from './components/routes/EmailReauthenticate';

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/admin/customers" element={<CustomersList />} />

      <Route path="/admin/customer" element={<CustomerDashboard />}>
        <Route path=":customerId" element={<CustomerDashboard />} />
      </Route>
      <Route path="/dashboard/me" element={<CustomerDashboard />} />
      <Route path="/profile/me" element={<Profile />} />
      <Route path="/reconnect" element={<EmailReauthenticate />} />
      <Route path="/admin/manage-users" element={<UsersManagement />} />
      <Route path="*" element={<Login />} />
    </Routes>
  );
}
