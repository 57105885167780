/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import * as Icons from 'react-feather';

import './style.css';
import clearSession from '../../utils/clearSession';

function LogOut(props) {
  const IconTag = Icons.Power;

  return (
    <div
      // className="cursor-pointer d-flex align-self-center justify-content-end"
      className="cursor-pointer"
      onClick={() => { clearSession(props.auth); }}
    >
      <div id="logOutLabel" className="text-center">Déconnexion</div>
      {props.showIcon && (
        <IconTag />
      )}
    </div>
  );
}
export default LogOut;
