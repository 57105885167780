/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import {
  Card, CardBody, CardHeader, CardText, CardTitle, Col, Row,
} from 'reactstrap';
import Chart from 'react-apexcharts';

// import '../../style/components/PointsBalance.css';
import './style.css';

function PointsBalance(props) {
  const generateHslPointsBarColor = (value) => {
    // value from 0 to 1
    const hue = ((1 - value) * 100).toString(10);
    return ['hsl(', hue, ',100%,41%)'].join('');
  };

  const handleMinimalPointsValue = (pointsLeftPercentage) => {
    if (100 - pointsLeftPercentage < 4) {
      return 4;
    } return 100 - pointsLeftPercentage;
  };

  const absBalanceData = Math.abs(props.balanceData.balanceIndex);
  const pointsBarColorBalanceIndex = generateHslPointsBarColor(absBalanceData * 1.7);
  const options = {
    chart: {
      type: 'radialBar',
      height: '100%',
      sparkline: {
        enabled: true,
      },
      dropShadow: {
        enabled: true,
        blur: 3,
        left: 1,
        top: 1,
        opacity: 0.1,
      },
    },
    colors: [pointsBarColorBalanceIndex, '#878787'],
    labels: ['Points utilisés', 'Temps écoulé'],
    legend: {
      show: true,
      floating: true,
      fontSize: '17px',
      position: 'bottom',
      offsetX: 0,
      offsetY: 7,
      onItemClick: {
        toggleDataSeries: false,
      },
      labels: {
        useSeriesColors: true,
        fontSize: '15px',
      },
      formatter(seriesName) {
        return seriesName;
      },
      itemMargin: {
        horizontal: 3,
      },
    },
    plotOptions: {
      radialBar: {
        offsetY: 10,
        startAngle: -150,
        endAngle: 150,
        hollow: {
          size: '65%',
          imageWidth: 46,
          imageHeight: 46,
          imageOffsetX: 0,
          imageOffsetY: 55,
          imageClipped: false,
        },
        track: {
          show: true,
          background: '#ebe9f1',
          strokeWidth: '80%',
        },
        dataLabels: {
          name: {
            show: true,
            color: '#000',
            fontSize: '16px',
            fontFamily: undefined,
            fontWeight: 600,
            offsetY: -15,
          },
          value: {
            show: true,
            offsetY: -5,
            color: '#000',
            fontFamily: 'Montserrat',
            fontWeight: '600',
            fontSize: '2.5em',
          },
          total: {
            show: true,
            label: props.balanceData.pointsLeft > 0 ? ('à utiliser') : 'hors forfait',
            color: '#000',
            fontFamily: 'Montserrat',
            fontSize: '1.20rem',
            formatter() {
              return `${Math.abs(props.balanceData.pointsLeft)}`;
            },
          },
        },
      },
    },
    stroke: {
      lineCap: 'round',
    },
    grid: {
      padding: {
        bottom: 30,
      },
    },
    states: {
      hover: {
        filter: {
          type: 'lighten',
          value: 0.1,
        },
      },
    },
  };

  const points = handleMinimalPointsValue(props.balanceData.pointsLeftPercentage);
  return (
    <Card style={{ flex: 1 }}>
      <CardHeader className="justify-content-center pb-0">
        <div className="d-flex justify-content-center align-items-center mt-1">
          <CardTitle tag="h3" className="mb-0 mt-0">Mes points</CardTitle>
        </div>
      </CardHeader>
      <CardBody className="p-0 d-flex justify-content-center" id="chartContainer">
        <Chart
          options={options}
          series={[points, 100 - props.balanceData.timeLeftPourcentage]}
          type="radialBar"
          height={245}
        />
      </CardBody>
      <Row className="text-center mx-0 additional-balance-data-row">
        <Col xs="6" className="border-right py-1">
          <CardText className="mb-0">Consommation</CardText>
          <h4 className="font-weight-bolder mb-0">
            {props.balanceData.pointsUsedInSub}
            {' '}
            /
            {props.balanceData.subValue}
          </h4>
          {props.balanceData.pointsInProgress > 0 && (
            <h6>
              dont
              {props.balanceData.pointsInProgress}
              {' '}
              en cours
            </h6>
          )}
        </Col>
        <Col xs="6" className="py-1">
          <CardText className="mb-0">Annualisation le</CardText>
          <h4 className="font-weight-bolder mb-0">{props.balanceData.renewDate}</h4>
        </Col>
      </Row>
    </Card>
  );
}
export default PointsBalance;
